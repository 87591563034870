import React from 'react';
import intl from 'react-intl-universal';
import Button from '../../../../components/Button/Button';
import './HeaderSend.css';

import World from '../../../../assets/images/header-send/send-globe.svg';
import Clouds from '../../../../assets/images/header-send/clouds.svg';

type Props = {
  title: string,
};

const HeaderSend = (props: Props) => (
  <header className="header-send">
    <div className="header-send__container">
      <div className="header-send__container-world" dangerouslySetInnerHTML={{ __html: World }}></div>
      <div className="header-send__container-clouds" dangerouslySetInnerHTML={{ __html: Clouds }}></div>
    </div>
    <div className="header-send__content">
      <div className="header-send__content-title">
        <h2 dangerouslySetInnerHTML={{ __html: props.title }}></h2>
      </div>
      <div className="header-send__content-input">
        <Button type="solid" link="https://auth.airtm.io/signup?redirect_uri=https://www.airtm.io/dashboard/new-setup&state=9e6d04c6-a846-48ff-9c7e-3fec1b292569">{intl.get('BUTTONS.GET-STARTED')}</Button>
        <span className="header-send__content-input-subtext">{intl.get('INPUT.SUB-TEXT')}</span>
      </div>
    </div>
  </header>
);


export default HeaderSend;
