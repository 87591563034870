import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import CircleTile from '../CircleTile/CircleTile';
import Tooltip from '../Tooltip/Tooltip';
import './Service.css';

type Props = {
  tiles?: Array,
  title: string,
  description: string,
  showIcons: number,
  imageUrl: Object,
  reverse: boolean,
  isLeft: boolean,
  extraCircle: boolean,
  circleNumber: string,
  toPage : string,
  extraLink: string,
};

const Features = (props: Props) => {
  const classes = classnames(['row'], {
    'reverse ': props.reverse,
    'm-left ': props.isLeft,
  });
  let tiles = null;
  let extraCircle = null;

  if (props.tiles && props.tiles.length > 0) {
    tiles = props.tiles.slice(0, props.showIcons).map(tile => (
      <Tooltip
        className="tile-grid__tile-small"
        text={tile.tooltip}
        key={tile.icon}
      >
        <CircleTile name={tile.icon} size={40} />
      </Tooltip>
    ));
  }

  if (props.extraCircle && props.extraLink) {
    extraCircle = <div className="extra-circle"><Link to={props.extraLink}>{props.circleNumber}</Link></div>;
  }

  return (
    <div className="service container">
      <div className={classes}>
        <div
          className="service__img col-lg-6 col-md-6 col-sm-12 col-xs-12"
          dangerouslySetInnerHTML={{ __html: props.imageUrl }}
        />
        <div className="service__content col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <h1 className="service__title">{props.title}</h1>
          <p className="service__description">{props.description}</p>
          <div className="service__tiles">
            {tiles}
            {extraCircle}
          </div>
          <div className="service__button">
            <Link to={props.toPage}>
              {intl.get('BUTTONS.LEARN-MORE')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
