// @flow
import React from 'react';
import './Testimonial.css';
import star from '../../assets/images/stars/star.svg';
import starGrey from '../../assets/images/stars/star-grey.svg';

type Props = {
  name: string,
  testimonial: string,
  image: string,
  fiveStars: boolean,
};

function getYellowOrGreyStar(yellowStar: boolean) {
  if (yellowStar) {
    return <span className="testimonial__star" dangerouslySetInnerHTML={{ __html: star }} />;
  }
  return <span className="testimonial__star" dangerouslySetInnerHTML={{ __html: starGrey }} />;
}

const testimonial = (props: Props) => (
  <div className="testimonial">
    <div className="testimonial__head">
      <div className="testimonial__image">
        <img src={props.image} alt="" />
      </div>
      <div className="testimonial__name">
        <p>{props.name}</p>
        <div>
          <span className="testimonial__star" dangerouslySetInnerHTML={{ __html: star }} />
          <span className="testimonial__star" dangerouslySetInnerHTML={{ __html: star }} />
          <span className="testimonial__star" dangerouslySetInnerHTML={{ __html: star }} />
          <span className="testimonial__star" dangerouslySetInnerHTML={{ __html: star }} />
          {getYellowOrGreyStar(props.fiveStars)}
        </div>
      </div>
    </div>
    <p
      className="testimonial__testimonial"
      dangerouslySetInnerHTML={{ __html: props.testimonial }}
    />
  </div>
);

export default testimonial;
