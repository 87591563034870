import React from 'react';
import intl from 'react-intl-universal';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import Footer from '../../components/Footer/Footer';
import Markdown from '../../components/Markdown/Markdown';
import termsOfServiceEs from './TermsEs.md';
import termsOfServiceEn from './TermsEn.md';
import privacyPolicyEs from './PrivacyEs.md';
import privacyPolicyEn from './PrivacyEn.md';
import cookiePolicyEs from './CookiePolicyEs.md';
import cookiePolicyEn from './CookiePolicyEn.md';
import './Legal.css';

class Legal extends React.Component {
  getCurrentLocale() {
    const { currentLocale } = intl.getInitOptions();
    return currentLocale ? currentLocale.split('-')[0] : 'en';
  }

  render() {
    return (
      <div>
        <div className="container">
          <div className="legal">
            <Tabs>
              <TabList>
                <Tab>{intl.get('LEGAL.TERMS')}</Tab>
                <Tab>{intl.get('LEGAL.PRIVACY')}</Tab>
                <Tab>{intl.get('LEGAL.COOKIE')}</Tab>
              </TabList>

              <TabPanel>
                <h2 className="legal__heading">{intl.get('LEGAL.TERMS')}</h2>
                <small className="legal__date">{intl.get('LEGAL.UPDATED', { updated: new Date('2019-09-25') })}</small>
                <hr/>
                <Markdown content={this.getCurrentLocale() === 'es' ? termsOfServiceEs : termsOfServiceEn } />
              </TabPanel>
              <TabPanel>
                <h2 className="legal__heading">{intl.get('LEGAL.PRIVACY')}</h2>
                <small className="legal__date">{intl.get('LEGAL.UPDATED', { updated: new Date('2019-09-25') })}</small>
                <hr/>
                <Markdown content={this.getCurrentLocale() === 'es' ? privacyPolicyEs : privacyPolicyEn } />
              </TabPanel>
              <TabPanel>
                <h2 className="legal__heading">{intl.get('LEGAL.COOKIE')}</h2>
                <small className="legal__date">{intl.get('LEGAL.UPDATED', { updated: new Date('2019-09-25') })}</small>
                <hr/>
                <Markdown content={this.getCurrentLocale() === 'es' ? cookiePolicyEs : cookiePolicyEn } />
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Legal;
