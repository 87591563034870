// @flow
import React from 'react';
import Calculator from 'Components/Calculator/Calculator';
import TabbedCalculator from 'Components/TabbedCalculator/TabbedCalculator';
import ShouldRender from 'Components/ShouldRender/ShouldRender';
import './CalculatorSection.css';

type Props = {
  title?: string,
  subtitle?: string,
  calculator?: Object,
  tabs?: Array<Object>,
}

const calculatorSection = (props: Props) => (
  <div className="section-calculator">
    <div className="container">
      <div className="section-calculator__title">{props.title}</div>
      <div className="section-calculator__subtitle">
        {props.subtitle}
      </div>

      <ShouldRender condition={!props.tabs || props.tabs.length === 0}>
        <Calculator
          {...props.calculator}
        />
      </ShouldRender>

      <ShouldRender condition={props.tabs && props.tabs.length > 0}>
        <TabbedCalculator
          tabs={props.tabs && props.tabs.length > 0 ? props.tabs : []}
        />
      </ShouldRender>


    </div>
  </div>
);

calculatorSection.defaultProps = {
  tabbed: false,
};

export default calculatorSection;
