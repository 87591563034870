// @flow
import React from 'react';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import ShouldRender from 'Components/ShouldRender/ShouldRender';
import CircleTile from '../CircleTile/CircleTile';
import Icons from '../../utils/Icons';
import './Select.css';

type Props = {
  label: string,
  options?: Array<Object>,
  onChange?: Function,
  onQueryChange?: Function,
  clear?: boolean,
  value?: Object,
  query?: string,
}

type State = {
  open: boolean,
}

class Select extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };

    this.setSelectRef = this.setSelectRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.showList = this.showList.bind(this);
    this.selectValue = this.selectValue.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.selectRef && !this.selectRef.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  setSelectRef(node) {
    this.selectRef = node;
  }

  showList(event) {
    if (event.target.tagName === 'INPUT') {
      return;
    }

    this.setState({
      open: !this.state.open,
    });
  }

  selectValue(value: Object) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  renderChoices() {
    return this.props.options.map((option, index) => (
      <div
        className="select__list-item"
        key={index}
        onClick={() => this.selectValue(option)}
      >
        <CircleTile className="select__list-item-icon" name={option.icon} />
        <span className="select__list-item-text">{option.text}</span>
      </div>
    ));
  }

  render() {
    const classes = classnames('select', {
      'select--clear': this.props.clear,
    });

    return (
      <div
        className={classes}
        onClick={this.showList}
        ref={this.setSelectRef}
      >
        <div className="select__selected-value">
          <CircleTile
            className="select__list-item-icon"
            name={this.props.value.icon}
          />
          <span
            className="select__list-item-text"
          >
            {this.props.value.text}
          </span>
        </div>
        <div
          className="select__icon"
          dangerouslySetInnerHTML={{ __html: Icons['caret-down'] }}
        />
        <ShouldRender condition={this.state.open}>
          <div className="select__list">
            <div className="select__list__input">
              <input type="text" placeholder={intl.get('CALCULATOR.SEARCH-PLACEHOLDER')} onChange={this.props.onQueryChange}/>
            </div>
            {this.renderChoices()}
          </div>
        </ShouldRender>
      </div>
    );
  }
}

Select.defaultProps = {
  label: 'Select One',
  clear: false,
};

export default Select;
