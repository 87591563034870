import React from 'react';

const wrapMarkup = html => ({
  __html: html,
});

// eslint-disable-next-line react/prop-types
export default function Markdown({ content }) {
  return (
    <div className="markdown" dangerouslySetInnerHTML={wrapMarkup(content)} />
  );
}
