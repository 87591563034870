// @flow
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import CalculatorSection from 'Components/CalculatorSection/CalculatorSection';
import BlogPostsSection from 'Components/BlogPostsSection/BlogPostsSection';
import Advantage from '../../components/Advantage/Advantage';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import HowStart from '../../components/HowStart/HowStart';
import PageBrief from '../../components/PageBrief/PageBrief';
import TestimonialsCarousel
  from '../../components/TestimonialsCarousel/TestimonialsCarousel';
import heroSave from '../../assets/images/hero-save.svg';
import coinsAdvantage from '../../assets/images/advantages/coins.png';
import worldAdvantage from '../../assets/images/advantages/world.png';
import cashAdvantage from '../../assets/images/advantages/cash.png';
import cloudAdvantage from '../../assets/images/advantages/cloud.png';
import Man1 from '../../assets/images/testimonials/m-1.png';
import Man2 from '../../assets/images/testimonials/m-2.png';
import Man3 from '../../assets/images/testimonials/m-3.png';
import noPostImage from '../../assets/images/no-post-image.png';
import './Save.css';
import calculatorAvatar from '../../assets/images/calculator-avatar-save.png';
import { mapStateToProps } from '../../services/airtm/connect';

type Props = {
  rates: Array<Object>,
  blog?: Array<Object>,
};

class Save extends React.Component<Props> {
  render() {
    const currencies = [
      { icon: 'dollar', tooltip: 'Dólar' },
      { icon: 'cent', tooltip: 'Cent' },
      { icon: 'bolivar', tooltip: 'Bolivar' },
      { icon: 'euro', tooltip: 'Euro' },
      { icon: 'yen', tooltip: 'Yen' },
      { icon: 'brazilianreal', tooltip: 'Real' },
      { icon: 'afgani', tooltip: 'Afgani' },
      { icon: 'diner', tooltip: 'Diner' },
      { icon: 'balboa', tooltip: 'Balboa' },
      { icon: 'sol', tooltip: 'Sol' },
      { icon: 'drham', tooltip: 'Drham' },
      { icon: 'rupee', tooltip: 'Rupee' },
    ];

    let posts = [];

    if (this.props.blog.blogPosts) {
      const cryptosPosts = this.props.blog.blogPosts.filter(post => post.categories.includes(14) && post.better_featured_image && post.better_featured_image.media_details && post.better_featured_image.media_details.sizes && post.better_featured_image.media_details.sizes['et-pb-post-main-image']);
      posts = cryptosPosts.map(post => ({
        image: post.better_featured_image === null ? noPostImage : post.better_featured_image.media_details.sizes['et-pb-post-main-image'].source_url,
        category: 'Ahorra en dólares',
        categoryColor: 'blue',
        title: post.title.rendered,
        excerpt: post.excerpt.rendered,
        link: post.link,
      }));

      if (posts.length >= 3) {
        posts = posts.slice(0, 3);
      } else {
        posts = [];
      }
    }

    return (
      <div>
        <Header
          image={heroSave}
          maxWidth={631}
          title={intl.get('SAVE.TITLE')}
        />

        <PageBrief
          text={intl.get('SAVE.SUB-TEXT')}
          tiles={currencies}
          more={intl.getHTML('SAVE.SUB-TEXT-CIRCLE')}
        />

        {false
        && <CalculatorSection
          title={intl.get('SAVE.CALCULATOR.TITLE')}
          subtitle={intl.get('SAVE.CALCULATOR.SUB-TITLE')}
          calculator={{
            sourceSelectText: intl.get('SAVE.CALCULATOR.SOURCE-LABEL'),
            sourceInputText: intl.get('SAVE.CALCULATOR.SOURCE-INPUT'),
            sourceOptions: this.props.rates.currencies,
            targetInputText: intl.get('SAVE.CALCULATOR.TARGET-INPUT'),
            targetOptions: false,
            image: calculatorAvatar,
            text: intl.getHTML('SAVE.CALCULATOR.TESTIMONIAL'),
            shareText: 'SAVE.CALCULATOR.SOCIAL-MESSAGE',
            useCase: 'preserve',
          }}
        />
        }

        <div className="cryptos-linear-background">
          <div className="container cryptos-advantage-container">
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
                <Advantage
                  image={coinsAdvantage}
                  title={intl.get('SAVE.ADVANTAGES.ITEM-ONE.TITLE')}
                  description={intl.get('SAVE.ADVANTAGES.ITEM-ONE.DESCRIPTION')}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
                <Advantage
                  image={worldAdvantage}
                  title={intl.get('SAVE.ADVANTAGES.ITEM-TWO.TITLE')}
                  description={intl.get('SAVE.ADVANTAGES.ITEM-TWO.DESCRIPTION')}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
                <Advantage
                  image={cashAdvantage}
                  title={intl.get('SAVE.ADVANTAGES.ITEM-THREE.TITLE')}
                  description={intl.get(
                    'SAVE.ADVANTAGES.ITEM-THREE.DESCRIPTION',
                  )}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
                <Advantage
                  image={cloudAdvantage}
                  title={intl.get('SAVE.ADVANTAGES.ITEM-FOUR.TITLE')}
                  description={intl.get(
                    'SAVE.ADVANTAGES.ITEM-FOUR.DESCRIPTION',
                  )}
                />
              </div>
            </div>
          </div>

          <div className="testi-container">
            <TestimonialsCarousel
              testimonials={[
                {
                  name: 'Jorge Solano',
                  testimonial: intl.get('TESTIMONIALS.SAVE.ONE'),
                  image: Man1,
                  fiveStars: false,
                },
                {
                  name: 'Jonathan Hidalgo',
                  testimonial: intl.get('TESTIMONIALS.SAVE.TWO'),
                  image: Man2,
                  fiveStars: true,
                },
                {
                  name: 'Richard García',
                  testimonial: intl.get('TESTIMONIALS.SAVE.THREE'),
                  image: Man3,
                  fiveStars: true,
                },
              ]}
            />
          </div>

          <div className="cryptos-how-start-container">
            <HowStart title={intl.get('HOW-START.TITLE')} />
          </div>

          <BlogPostsSection
            posts={posts}
          />
        </div>

        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps)(Save);
