import {
  call,
  put,
  takeLatest,
  take,
  fork,
  END,
} from 'redux-saga/effects';
import {
  REQUEST_GET_BLOG_POSTS,
  RESPONSE_GET_BLOG_POSTS,
  FAILURE_GET_BLOG_POSTS,
} from './blog/actionTypes';
import BlogAPI from './blog/API';
import milestones from './milestones/sagas';

const allSagas = [
  milestones,
];

function getTasks(action) {
  const tasks = [];
  for (let i = 0; i < allSagas.length; i += 1) {
    const task = allSagas[i](action);
    if (task !== null && task !== undefined) {
      tasks.push(task);
    }
  }
  return tasks;
}

function* requestBlogPosts() {
  const blogApi = new BlogAPI();

  try {
    const posts = yield call(blogApi.fetchBlogPosts);

    yield put({
      type: RESPONSE_GET_BLOG_POSTS,
      blogPosts: posts,
    });
  } catch (e) {
    yield put({
      type: FAILURE_GET_BLOG_POSTS,
      message: e.message,
    });
  }
}

function* universalSaga() {
  let action;
  do {
    action = yield take('*');
    const tasks = getTasks(action);
    if (tasks.length > 0) {
      for (let i = 0; i < tasks.length; i += 1) {
        yield fork(tasks[i], action);
      }
    }
  } while (action !== END);
}

function* saga() {
  yield takeLatest(REQUEST_GET_BLOG_POSTS, requestBlogPosts);
  yield fork(universalSaga);
}

export default saga;
