// @flow
import React from 'react';
import chunkArray from '../../utils/array/chunkArray';
import Separator from '../Separator/Separator';
import CircleTile from '../CircleTile/CircleTile';
import Tooltip from '../Tooltip/Tooltip';
import Modal from '../Modal/Modal';
import './PageBrief.css';

type Props = {
  image: any,
  text: string,
  more: string,
  tiles: Array,
};

const PageBrief = (props: Props) => {
  const chunks = chunkArray(props.tiles, 6);

  return (
    <div className="container">
      <div className="brief__container">
        <Separator image={props.image} />

        <p className="brief__text">{props.text}</p>

        {chunks.map((chunk, chunkIndex) => (
          <div key={chunkIndex} className="brief__circle-container">
            {chunk.map((tile, index) => (
              <div key={index}
                style={{
                  width: '100%',
                  height: '100%',
                }}>
                <Tooltip text={tile.tooltip} key={tile.icon} className="brief__tooltip" >
                  <CircleTile className="brief__circle-tile" name={tile.icon} size={100} />
                </Tooltip>
              </div>
            ))}
          </div>
        ))}
        <div className="brief__more">
          <Modal text={props.more} />
        </div>
      </div>
    </div>
  );
};

export default PageBrief;
