// @flow
import React from 'react';
import intl from 'react-intl-universal';
import cookie from 'react-cookie';
import qs from 'qs';
import HeaderHome from './components/HeaderHome/HeaderHome';
import Milestones from '../../components/Milestones/Milestones';
import PaymentsMethods from './components/PaymentsMethods/PaymentsMethods';
import Service from '../../components/Service/Service';
import Advantage from '../../components/Advantage/Advantage';
import TestimonialsCarousel from '../../components/TestimonialsCarousel/TestimonialsCarousel';
import HowStart from '../../components/HowStart/HowStart';
import LogosBrand from './components/LogosBrand/LogosBrand';
import Map from '../../components/Map/Map';
import Footer from '../../components/Footer/Footer';
import './Home.css';
import ServiceSvgOne from '../../assets/images/services/service-1.svg';
import ServiceSvgTwo from '../../assets/images/services/service-2.svg';
import ServiceSvgThree from '../../assets/images/services/service-3.svg';
import currencyAdvantage from '../../assets/images/advantages/currency.png';
import atentionAdvantage from '../../assets/images/advantages/atention.png';
import clockAdvantage from '../../assets/images/advantages/clock.png';
import shieldAdvantage from '../../assets/images/advantages/shield.png';
import Woman1 from '../../assets/images/testimonials/f-1.png';
import Man4 from '../../assets/images/testimonials/m-4.png';
import Man5 from '../../assets/images/testimonials/m-5.png';
import Woman7 from '../../assets/images/testimonials/f-6.png';
import Woman2 from '../../assets/images/testimonials/f-2.png';
import Man1 from '../../assets/images/testimonials/m-1.png';

type Props = {
  name: string,
  location: string,
};

type State = {};

class Home extends React.Component<Props, State> {
  componentDidMount() {
    window.addEventListener('scroll', this.scrollAnimated);
    // Add Marketing cookies
    let hostName = window.location.hostname;
    hostName = hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    let hasUtmData = false;
    Object.entries(queryParams)
      .forEach(([key, value]) => {
        if (key.startsWith('utm_')) {
          hasUtmData = true;
          cookie.save(key, value, {
            path: '/',
            expires: expirationDate,
            domain: hostName,
          });
        }
      });

    if (document.referrer !== '' && hasUtmData) {
      cookie.save('utm_referrer', document.referrer, {
        path: '/',
        expires: expirationDate,
        domain: hostName,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollAnimated);
  }

  scrollAnimated() {
    const moveRight = document.getElementById('pay-one');
    const moveLeft = document.getElementById('pay-two');

    if (window.screen.width > 758 && moveRight && moveLeft) {
      const scroll = window.pageYOffset;

      moveRight.style.transform = `translateX(${scroll + 100}px)`;
      moveLeft.style.transform = `translateX(-${scroll}px)`;
    }
  }

  render() {
    const paymentsMethodsOne = [
      { icon: 'rapipago', tooltip: 'Rapipago' },
      { icon: 'orange', tooltip: 'Orange Money' },
      { icon: 'mpesa', tooltip: 'Mpesa' },
      { icon: 'mercadopago', tooltip: 'Mercado Pago' },
      { icon: 'ali-pay', tooltip: 'Ali Pay' },
    ];

    const paymentsMethodsTwo = [
      { icon: 'money-gram', tooltip: 'Money Gram' },
      { icon: 'western-union', tooltip: 'Western Union' },
      { icon: 'pago-facil', tooltip: 'Pago Fácil' },
      { icon: 'mtn', tooltip: 'MTN Mobile' },
      { icon: 'uphold', tooltip: 'Uphold' },
      { icon: 'skrill', tooltip: 'Skrill' },
    ];

    const countries = [
      { icon: 'argentina', tooltip: 'Argentina' },
      { icon: 'brazil', tooltip: 'Brasil' },
      { icon: 'chile', tooltip: 'Chile' },
      { icon: 'china', tooltip: 'China' },
      { icon: 'colombia', tooltip: 'Colombia' },
      { icon: 'india', tooltip: 'India' },
      { icon: 'cuba', tooltip: 'Cuba' },
      { icon: 'germany', tooltip: 'Alemania' },
      { icon: 'greece', tooltip: 'Gracia' },
      { icon: 'honduras', tooltip: 'Honduras' },
      { icon: 'hongkong', tooltip: 'Hong-Kong' },
      { icon: 'iraq', tooltip: 'Iraq' },
      { icon: 'italia', tooltip: 'Italia' },
      { icon: 'japan', tooltip: 'Japón' },
      { icon: 'mexico', tooltip: 'México' },
      { icon: 'nigeria', tooltip: 'Nigeria' },
      { icon: 'panama', tooltip: 'Panama' },
      { icon: 'peru', tooltip: 'Perú' },
      { icon: 'portugal', tooltip: 'Portugal' },
      { icon: 'qatar', tooltip: 'Qatar' },
      { icon: 'sierraleona', tooltip: 'Sierra Leona' },
      { icon: 'southafrica', tooltip: 'South Africa' },
      { icon: 'spain', tooltip: 'España' },
      { icon: 'us', tooltip: 'Estados Unidos' },
      { icon: 'venezuela', tooltip: 'Venezuela' },
      { icon: 'world', tooltip: 'Mundo' },
    ];

    const currencies = [
      { icon: 'afgani', tooltip: 'Afgani' },
      { icon: 'balboa', tooltip: 'Balboa' },
      { icon: 'bolivar', tooltip: 'Bolivar' },
      { icon: 'brazilianreal', tooltip: 'Real' },
      { icon: 'cent', tooltip: 'Cent' },
      { icon: 'diner', tooltip: 'Diner' },
      { icon: 'dollar', tooltip: 'Dólar' },
      { icon: 'drham', tooltip: 'Drham' },
      { icon: 'euro', tooltip: 'Euro' },
      { icon: 'rupee', tooltip: 'Rupee' },
      { icon: 'sol', tooltip: 'Sol' },
      { icon: 'yen', tooltip: 'Yen' },
    ];

    return (
      <div>
        <HeaderHome parragraph={intl.get('HOME.HEADER.DESCRIPTION')} />
        <PaymentsMethods
          tilesOne={paymentsMethodsOne}
          tilesTwo={paymentsMethodsTwo}
          title={intl.get('HOME.PAYMENTS-TITLE')}
        />

        <Milestones intl={intl} />
        <div className="services-container">
          <Service
            tiles={currencies}
            title={intl.get('HOME.SERVICES.SAVE.TITLE')}
            description={intl.get('HOME.SERVICES.SAVE.DESCRIPTION')}
            showIcons={6}
            imageUrl={ServiceSvgOne}
            toPage="/save"
            reverse={false}
          />
        </div>
        <div className="services-container">
          <Service
            tiles={countries}
            title={intl.get('HOME.SERVICES.SEND.TITLE')}
            description={intl.get('HOME.SERVICES.SEND.DESCRIPTION')}
            showIcons={6}
            imageUrl={ServiceSvgTwo}
            reverse={true}
            isLeft={true}
            extraCircle={true}
            toPage="/send"
            extraLink="/send"
            circleNumber="157"
          />
        </div>
        <div className="services-container">
          <Service
            tiles={paymentsMethodsOne}
            title={intl.get('HOME.SERVICES.WITHDRAW.TITLE')}
            description={intl.get('HOME.SERVICES.WITHDRAW.DESCRIPTION')}
            showIcons={4}
            imageUrl={ServiceSvgThree}
            extraCircle={true}
            circleNumber="12"
            toPage="/free"
            reverse={false}
          />
        </div>

        <div className="advantage-container container">
          <div className="row center-xs">
            <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
              <Advantage
                image={currencyAdvantage}
                title={intl.get('HOME.ADVANTAGES.ITEM-ONE.TITLE')}
                description={intl.get('HOME.ADVANTAGES.ITEM-ONE.DESCRIPTION')}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
              <Advantage
                image={atentionAdvantage}
                title={intl.get('HOME.ADVANTAGES.ITEM-TWO.TITLE')}
                description={intl.get('HOME.ADVANTAGES.ITEM-TWO.DESCRIPTION')}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
              <Advantage
                image={clockAdvantage}
                title={intl.get('HOME.ADVANTAGES.ITEM-THREE.TITLE')}
                description={intl.get('HOME.ADVANTAGES.ITEM-THREE.DESCRIPTION')}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
              <Advantage
                image={shieldAdvantage}
                title={intl.get('HOME.ADVANTAGES.ITEM-FOUR.TITLE')}
                description={intl.get('HOME.ADVANTAGES.ITEM-FOUR.DESCRIPTION')}
              />
            </div>
          </div>
        </div>

        <div className="testimonials-container">
          <TestimonialsCarousel
            testimonials={[
              {
                name: 'Damarys Ramírez',
                testimonial: intl.get('TESTIMONIALS.FREE.TWO'),
                image: Woman1,
                fiveStars: false,
              },
              {
                name: 'Daniel',
                testimonial: intl.get('TESTIMONIALS.CRYPTO.TWO'),
                image: Man4,
                fiveStars: true,
              },
              {
                name: 'Victor',
                testimonial: intl.get('TESTIMONIALS.SEND.THREE'),
                image: Man5,
                fiveStars: true,
              },
              {
                name: 'Argenis Urseche',
                testimonial: intl.get('TESTIMONIALS.CRYPTO.THREE'),
                image: Woman7,
                fiveStars: true,
              },
              {
                name: 'KarlysPDN',
                testimonial: intl.get('TESTIMONIALS.SEND.TWO'),
                image: Woman2,
                fiveStars: true,
              },
              {
                name: 'Jorge Solano',
                testimonial: intl.get('TESTIMONIALS.SAVE.ONE'),
                image: Man1,
                fiveStars: false,
              },
            ]}
          />
        </div>
        <LogosBrand />
        <HowStart title={intl.get('HOW-START.TITLE')} />
        <Map />
        <Footer />
      </div>
    );
  }
}

export default Home;
