// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollToTop from 'Components/ScrollToTop/ScrollToTop';
import store from './store';
import App from './App.jsx';
import './app.css';

const app = document.getElementById('app');
if (app !== null) {
  ReactDOM.render((
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Router>
    </Provider>
  ), app);
}
