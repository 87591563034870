import React from 'react';
import LogoCoindesk from '../../../../assets/images/brands/coindesk.svg';
import LogoBloomberg from '../../../../assets/images/brands/bloomberg.svg';
import LogoTechcrunch from '../../../../assets/images/brands/techcrunch.svg';
import LogoPyments from '../../../../assets/images/brands/pymnts.svg';
import LogoExpansion from '../../../../assets/images/brands/expansion.svg';
import './LogosBrand.css';

const logosBrand = () => (
  <div className="logos">
    <div className="container">
      <div className="logos__brand">
        <div className="logos__brand-svg">
          <a href="https://www.coindesk.com/anti-petro-zcash-throwing-venezuelans-lifeline/" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: LogoCoindesk }}></a>
        </div>
        <div className="logos__brand-svg">
          <a href="https://www.bloomberg.com/news/articles/2018-04-04/venezuela-s-currency-is-doing-even-worse-than-previously-thought" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: LogoBloomberg }}></a>
        </div>
        <div className="logos__brand-svg">
          <a href="https://techcrunch.com/2018/08/29/airtm-raises-7-million-to-fight-hyperinflation/(https://techcrunch.com/2018/08/29/airtm-raises-7-million-to-fight-hyperinflation/)" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: LogoTechcrunch }}></a>
        </div>
        <div className="logos__brand-svg">
          <a href="https://www.pymnts.com/news/international/latin-america/2018/airtm-vef-paypal-blockchain-venezuela/(https://www.pymnts.com/news/international/latin-america/2018/airtm-vef-paypal-blockchain-venezuela/)" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: LogoPyments }}></a>
        </div>
        <div className="logos__brand-svg">
          <a href="https://expansion.mx/emprendedores/2018/03/15/los-4-cazadores-de-bitcoins-y-otras-criptomonedas#uuid00000163-ffad-db07-a167-ffad027b0007" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: LogoExpansion }}></a>
        </div>
      </div>
    </div>
  </div>
);

export default logosBrand;
