import React from 'react';
import Icons from '../../utils/Icons';
import './CircleTile.css';

type Props = {
  name: string,
  className: string,
  size: number,
};

const circleTile = (props: Props) => (
  <div
    className={`circle-tile ${props.className}`}
    dangerouslySetInnerHTML={{ __html: Icons[props.name] }}
    style={{
      width: props.size,
      height: props.size,
    }}
  />
);

circleTile.defaultProps = {
  name: 'world',
  className: '',
  size: 32,
};

export default circleTile;
