exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../node_modules/css-loader/index.js??ref--6-1!../node_modules/postcss-loader/lib/index.js!./assets/styles/variables.css"), "");
exports.i(require("-!../node_modules/css-loader/index.js??ref--6-1!../node_modules/postcss-loader/lib/index.js!./assets/styles/minireset.css"), "");
exports.i(require("-!../node_modules/css-loader/index.js??ref--6-1!../node_modules/postcss-loader/lib/index.js!./assets/styles/fonts.css"), "");
exports.i(require("-!../node_modules/css-loader/index.js??ref--6-1!../node_modules/postcss-loader/lib/index.js!../node_modules/flexboxgrid/dist/flexboxgrid.min.css"), "");

// module
exports.push([module.id, "html * {\n    box-sizing: border-box;\n    font-family: var(--font-primary);\n    font-family: var(--font-primary);\n  }\n\nbody {\n  height: 100vh;\n  font-family: var(--font-primary);\n  font-family: var(--font-primary);\n}\n\n.app {\n  margin-top: -100px;\n}\n\n.mt-80 {\n  margin-top: 80px;\n}\n\n.hidden {\n  display: none !important;\n}", ""]);

// exports
