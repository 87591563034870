// @flow

function ellipsis(text: string, limit: number = 0) {
  if (text.length <= limit) {
    return text;
  }

  const subString = text.substr(0, limit - 1);
  return `${subString.substr(0, subString.lastIndexOf(' '))}...`;
}

export default ellipsis;
