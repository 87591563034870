// @flow
import React from 'react';
import intl from 'react-intl-universal';
import Image1 from '../../../assets/images/company/masonry/1.jpg';
import Image2 from '../../../assets/images/company/masonry/2.jpg';
import Image3 from '../../../assets/images/company/masonry/3.jpg';
import Image4 from '../../../assets/images/company/masonry/4.jpg';
import './CompanyMasonry.css';

const CompanyMasonry = () => (
  <div className="company-masonry__background">
    <div className="company-masonry container">
      <div className="company-masonry__heading">{intl.get('COMPANY.AIRTM-ABOUT.OFFICES')}</div>
      <div className="company-masonry__container">
        <div className="company-masonry__text">
          <div>
            <p className="company-masonry__text-normal">
              {intl.get('COMPANY.AIRTM-ABOUT.COLLABORATE')}
            </p>
            <p className="company-masonry__text-strong">hello@airtm.io</p>
          </div>
        </div>

        <div className="company-masonry__image-1">
          <img src={Image1} alt="" srcSet="" />
        </div>
        <div className="company-masonry__image-2">
          <img src={Image2} alt="" srcSet="" />
        </div>
        <div className="company-masonry__image-3">
          <img src={Image3} alt="" srcSet="" />
        </div>
        <div className="company-masonry__image-4">
          <img src={Image4} alt="" srcSet="" />
        </div>
      </div>
    </div>
  </div>
);

export default CompanyMasonry;
