export default {
  currenciesForConversion: [
    { icon: 'afgani', text: 'Afgani' },
    { icon: 'balboa', text: 'Balboa' },
    { icon: 'bolivar', text: 'Bolivar' },
    { icon: 'brazilianreal', text: 'Real' },
    { icon: 'cent', text: 'Cent' },
    { icon: 'diner', text: 'Diner' },
    { icon: 'dollar', text: 'Dólar' },
    { icon: 'drham', text: 'Drham' },
    { icon: 'euro', text: 'Euro' },
    { icon: 'rupee', text: 'Rupee' },
    { icon: 'sol', text: 'Sol' },
    { icon: 'yen', text: 'Yen' },
  ],
  currencies: [
    { icon: 'afgani', text: 'Afgani' },
    { icon: 'balboa', text: 'Balboa' },
    { icon: 'bolivar', text: 'Bolivar' },
    { icon: 'brazilianreal', text: 'Real' },
    { icon: 'cent', text: 'Cent' },
    { icon: 'diner', text: 'Diner' },
    { icon: 'dollar', text: 'Dólar' },
    { icon: 'drham', text: 'Drham' },
    { icon: 'euro', text: 'Euro' },
    { icon: 'rupee', text: 'Rupee' },
    { icon: 'sol', text: 'Sol' },
    { icon: 'yen', text: 'Yen' },
  ],
  cryptos: [
    {
      icon: 'zec', text: 'Z-cash', Buy: 1, Sell: 1, Code: 'ZEC',
    },
    {
      icon: 'bch', text: 'Bitcoin Cash', Buy: 1, Sell: 1, Code: 'BCH',
    },
    {
      icon: 'ltc', text: 'Litecoin', Buy: 1, Sell: 1, Code: 'LTC',
    },
    {
      icon: 'monero', text: 'Monero', Buy: 1, Sell: 1, Code: 'MON',
    },
    {
      icon: 'btc', text: 'Bitcoin', Buy: 1, Sell: 1, Code: 'BTC',
    },
    {
      icon: 'eth', text: 'Ethereum', Buy: 1, Sell: 1, Code: 'ETH',
    },
    {
      icon: 'doge', text: 'Dogecoin', Buy: 1, Sell: 1, Code: 'DOGE',
    },
    {
      icon: 'xrp', text: 'Ripple', Buy: 1, Sell: 1, Code: 'XRP',
    },
    {
      icon: 'usdt', text: 'Tether', Buy: 1, Sell: 1, Code: 'USDT',
    },
  ],
  paymentsMethods: [
    {
      icon: 'ali-pay',
      text: 'Ali Pay',
      Buy: 1,
      Sell: 1,
      Rate: 1,
      Code: 'AirUSD',
    },
    {
      icon: 'mercadopago',
      text: 'Mercado Pago',
      Buy: 1,
      Sell: 1,
      Rate: 1,
      Code: 'AirUSD',
    },
    {
      icon: 'neteller',
      text: 'Neteller',
      Buy: 1,
      Sell: 1,
      Rate: 1,
      Code: 'AirUSD',
    },
    {
      icon: 'payeer', text: 'Payeer', Buy: 1, Sell: 1, Rate: 1, Code: 'AirUSD',
    },
    {
      icon: 'perfect-money',
      text: 'Perfect Money',
      Buy: 1,
      Sell: 1,
      Rate: 1,
      Code: 'AirUSD',
    },
    {
      icon: 'skrill', text: 'Skrill', Buy: 1, Sell: 1, Rate: 1, Code: 'AirUSD',
    },
    {
      icon: 'tenpay', text: 'Tenpay', Buy: 1, Sell: 1, Rate: 1, Code: 'AirUSD',
    },
    {
      icon: 'uphold', text: 'Uphold', Buy: 1, Sell: 1, Rate: 1, Code: 'AirUSD',
    },
  ],
  countries: [
    { icon: 'argentina', text: 'Argentina' },
    { icon: 'brazil', text: 'Brasil' },
    { icon: 'chile', text: 'Chile' },
    { icon: 'china', text: 'China' },
    { icon: 'colombia', text: 'Colombia' },
    { icon: 'india', text: 'India' },
    { icon: 'cuba', text: 'Cuba' },
    { icon: 'germany', text: 'Alemania' },
    { icon: 'greece', text: 'Gracia' },
    { icon: 'honduras', text: 'Honduras' },
    { icon: 'hongkong', text: 'Hong-Kong' },
    { icon: 'iraq', text: 'Iraq' },
    { icon: 'italia', text: 'Italia' },
    { icon: 'japan', text: 'Japón' },
    { icon: 'mexico', text: 'México' },
    { icon: 'nigeria', text: 'Nigeria' },
    { icon: 'panama', text: 'Panama' },
    { icon: 'peru', text: 'Perú' },
    { icon: 'portugal', text: 'Portugal' },
    { icon: 'qatar', text: 'Qatar' },
    { icon: 'sierraleona', text: 'Sierra Leona' },
    { icon: 'southafrica', text: 'South Africa' },
    { icon: 'spain', text: 'España' },
    { icon: 'us', text: 'Estados Unidos' },
    { icon: 'venezuela', text: 'Venezuela' },
    { icon: 'world', text: 'Mundo' },
  ],
};
