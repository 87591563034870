// @flow
import * as React from 'react';
import HighlightedText from '../HighlightedText/HighlightedText';

type MilestonesProps = {
  intl: {
    get: string => string,
  },
};

class Milestones extends React.Component<MilestonesProps> {
  render() {
    const { intl } = this.props;

    return (
      <div className="highlighted-container container">
        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className="box">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-6">
                  {
                    <HighlightedText
                      number={'591,933'}
                      sub={intl.get('HOME.HIGHLIGHTED-TEXT.SUB-TEXT-ONE')}
                    />
                  }
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  {
                    <HighlightedText
                      number={'3,500,025'}
                      sub={intl.get('HOME.HIGHLIGHTED-TEXT.SUB-TEXT-TWO')}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12">
            <div className="box">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-6">
                  {
                    <HighlightedText
                      number={'+200'}
                      sub={intl.get('HOME.HIGHLIGHTED-TEXT.SUB-TEXT-THREE')}
                    />
                  }
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  {
                    <HighlightedText
                      number={'163'}
                      sub={intl.get('HOME.HIGHLIGHTED-TEXT.SUB-TEXT-FOUR')}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Milestones;
