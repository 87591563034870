// @flow
import React from 'react';
import intl from 'react-intl-universal';
import Button from '../Button/Button';
import './Header.css';

type Props = {
  image: Object,
  title: string,
  maxWidth: number,
  className: string,
};

const Header = (props: Props) => (
  <header className={`header-general ${props.className}`}>
    <div className="header-general__container container">
      <div className="row center-md">
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <div className="header-general__content">
            <div
              className="header-general__content-image"
              style={{ maxWidth: `${props.maxWidth}px` }}
              dangerouslySetInnerHTML={{ __html: props.image }}
            />
            <div className="header-general__content-title">
              <h2 dangerouslySetInnerHTML={{ __html: props.title }} />
            </div>
            <div className="header-general__content-input">
              <Button type="solid" link="https://auth.airtm.io/signup?redirect_uri=https://www.airtm.io/dashboard/new-setup&state=9e6d04c6-a846-48ff-9c7e-3fec1b292569">{intl.get('BUTTONS.GET-STARTED')}</Button>
              <span className="header-general__content-input-subtext">{intl.get('INPUT.SUB-TEXT')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);

Header.defaultProps = {
  maxWidth: 330,
  className: '',
};

export default Header;
