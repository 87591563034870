import { call, put, fork } from 'redux-saga/effects';
import axios from 'axios';
import {
  ACTION_GET_FEED,
  ACTION_GET_FEED_FIELD,
  ACTION_GET_FEED_SUCCESS,
  ACTION_GET_FEED_ERROR,
} from './constants';

function* getFeedItem(field) {
  try {
    yield put({
      type: ACTION_GET_FEED_FIELD,
      field,
    });
    const response = yield call(() => axios.get(`/slowpoke/api/v1/feed/${field}Count`));
    const { data } = response;
    const count = data[`${field}Count`];
    yield put({
      type: ACTION_GET_FEED_SUCCESS,
      count,
      field,
    });
  } catch (error) {
    yield put({
      type: ACTION_GET_FEED_ERROR,
      error,
      field,
    });
  }
}

function* getFeed() {
  yield fork(getFeedItem, 'users');
  yield fork(getFeedItem, 'operations');
}

export default ({ type }) => {
  switch (type) {
    case ACTION_GET_FEED:
      return getFeed;
    default:
      return null;
  }
};
