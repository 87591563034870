// @flow
import React from 'react';
import './Advantage.css';

type Props = {
  image: string,
  title: string,
  description: string,
};

const advantage = (props: Props) => (
  <div className="advantage">
    <div className="advantage__image-container">
      <div className="advantage__image-line" />
      <img src={props.image} className="advantage__image-image" alt="" />
      <div className="advantage__image-line" />
    </div>
    <p className="advantage__title">{props.title}</p>
    <p className="advantage__description">{props.description}</p>
  </div>
);

export default advantage;
