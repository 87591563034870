// @flow
import React from 'react';
import intl from 'react-intl-universal';
import StartItem from './StartItem/StartItem';
import IconOne from '../../assets/images/start/start-01.svg';
import IconTwo from '../../assets/images/start/start-02.svg';
import IconThree from '../../assets/images/start/start-03.svg';
import Button from '../Button/Button';
import './HowStart.css';

type Props = {
  title: string,
};

const howStart = (props: Props) => (
  <div className="start-bg-container">
    <div className="container">
      <div className="start-container">
        <div className="start__title">
          <h3 className="start__title-text">{props.title}</h3>
        </div>
        <div className="start__content row">
          <StartItem
            icon={IconOne}
            number="1"
            description={intl.get('HOW-START.CREATE')}
            line="left"
          />
          <StartItem
            icon={IconTwo}
            number="2"
            description={intl.get('HOW-START.DEPOSIT')}
          />
          <StartItem
            icon={IconThree}
            number="3"
            description={intl.get('HOW-START.SAVE')}
            line="right"
          />
        </div>
        <div className="start__email">
          <Button type="solid" link="https://auth.airtm.io/signup?redirect_uri=https://www.airtm.io/dashboard/new-setup&state=9e6d04c6-a846-48ff-9c7e-3fec1b292569">{intl.get('BUTTONS.GET-STARTED')}</Button>
          <span className="start__email-subtext">
            {intl.get('INPUT.SUB-TEXT')}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default howStart;
