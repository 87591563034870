// @flow
import React from 'react';
import IconClose from '../../assets/images/close.svg';
import './WelcomeBar.css';

type Props = {
  text: string,
  buttonText: string,
  isHidden: boolean,
};

class WelcomeBar extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.props = props;

    this.state = {
      isHidden: false,
    };

    this.toggleHidden = this.toggleHidden.bind(this);

    if (window.localStorage.getItem('acceptWelcome') === null) {
      window.localStorage.setItem('acceptWelcome', 'false');
    }

    if (window.localStorage.getItem('acceptWelcome') === 'true') {
      this.state = {
        isHidden: true,
      };
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
    window.localStorage.setItem('acceptWelcome', 'true');
  }

  displayWelcomBar(isHidden: boolean) {
    if (isHidden) {
      return <div />;
    }
    return (
      <div className="welcome-bar">
        <div className="welcome-bar__content container">
          <div className="welcome-bar__content__group">
            <div className="welcome-bar__content__group-icon" dangerouslySetInnerHTML={{ __html: IconClose }} onClick={this.toggleHidden}></div>
            <div className="welcome-bar__content__group-text" dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
            <a className="welcome-bar__content-button welcome-bar__content__group-text">
              {this.props.buttonText}
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    // return this.displayWelcomBar(this.state.isHidden);
    return <div />;
  }
}

export default WelcomeBar;
