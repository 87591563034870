import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import CalculatorSection from 'Components/CalculatorSection/CalculatorSection';
import BlogPostsSection from 'Components/BlogPostsSection/BlogPostsSection';
import FreeBrief from './components/FreeBrief/FreeBrief';
import Advantage from '../../components/Advantage/Advantage';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import HowStart from '../../components/HowStart/HowStart';
import TestimonialsCarousel
  from '../../components/TestimonialsCarousel/TestimonialsCarousel';
import heroFree from '../../assets/images/hero-free.svg';
import currencyAdvantage from '../../assets/images/advantages/currency.png';
import worldAdvantage from '../../assets/images/advantages/world.png';
import clockAdvantage from '../../assets/images/advantages/clock.png';
import cloudAdvantage from '../../assets/images/advantages/cloud.png';
import Man7 from '../../assets/images/testimonials/m-7.png';
import Woman1 from '../../assets/images/testimonials/f-1.png';
import Man5 from '../../assets/images/testimonials/m-5.png';
import noPostImage from '../../assets/images/no-post-image.png';
import './Free.css';
import paymentProcessorsAvatar
  from '../../assets/images/calculator-free-processors.png';
import giftCardsAvatar
  from '../../assets/images/calculator-avatar-gift-cards.png';
import { mapStateToProps } from '../../services/airtm/connect';
import Options from '../../options';

type Props = {
  rates?: Array<Object>,
  blog?: Array<Object>,
}

class Free extends React.Component<Props> {
  render() {
    const giftCards = [
      { icon: 'airbnb', tooltip: 'Airbnb' },
      { icon: 'netflix', tooltip: 'Netflix' },
      { icon: 'itunes', tooltip: 'iTunes' },
      { icon: 'spotify', tooltip: 'Spotify' },
      { icon: 'playstation', tooltip: 'PlayStation' },
      { icon: 'google-play', tooltip: 'Google Play' },
    ];

    const paymentProcessors = [
      { icon: 'neteller', tooltip: 'Neteller' },
      { icon: 'uphold', tooltip: 'Uphold' },
      { icon: 'ali-pay', tooltip: 'Ali Pay' },
      { icon: 'payeer', tooltip: 'Payeer' },
      { icon: 'mercadopago', tooltip: 'Mercado Pago' },
      { icon: 'skrill', tooltip: 'Skrill' },
    ];

    const mobileTransfers = [
      { icon: 'mpesa', tooltip: 'Mpesa' },
      { icon: 'orange', tooltip: 'Orange Money' },
      { icon: 'mtn', tooltip: 'MTN Mobile' },
    ];

    const url = window.location.href.replace(/#/, '%23');

    let posts = [];

    if (this.props.blog.blogPosts) {
      const cryptosPosts = this.props.blog.blogPosts.filter(post => post.categories.includes(15) && post.better_featured_image && post.better_featured_image.media_details && post.better_featured_image.media_details.sizes && post.better_featured_image.media_details.sizes['et-pb-post-main-image']);
      posts = cryptosPosts.map(post => ({
        image: post.better_featured_image === null ? noPostImage : post.better_featured_image.media_details.sizes['et-pb-post-main-image'].source_url,
        category: 'Libera tu dinero',
        categoryColor: 'pinky',
        title: post.title.rendered,
        excerpt: post.excerpt.rendered,
        link: post.link,
      }));

      if (posts.length >= 3) {
        posts = posts.slice(0, 3);
      } else {
        posts = [];
      }
    }

    return (
      <div>
        <Header image={heroFree} maxWidth={631}
                title={intl.get('FREE.TITLE')} />

        <FreeBrief
          text={intl.getHTML('FREE.SUB-TITLE')}
          firstTitle={intl.get('FREE.PAYMENTS')}
          firstTiles={paymentProcessors}
          secondTitle={intl.get('FREE.GIFT-CARDS')}
          secondTiles={giftCards}
          thridTitle={intl.get('FREE.MOBILE-TRANSFER')}
          thridTiles={mobileTransfers}
        />

        { false
        && <CalculatorSection
          title={intl.get('FREE.CALCULATOR.TITLE')}
          subtitle={intl.get('FREE.CALCULATOR.SUB-TITLE')}
          tabs={[
            {
              text: intl.get('FREE.CALCULATOR.TAB-ONE'),
              calculator: {
                sourceSelectText: intl.get('FREE.CALCULATOR.SOURCE-LABEL'),
                sourceInputText: intl.get('FREE.CALCULATOR.SOURCE-INPUT'),
                sourceOptions: Options.paymentsMethods,
                targetInputText: intl.get('FREE.CALCULATOR.TARGET-INPUT'),
                targetOptions: false,
                image: paymentProcessorsAvatar,
                text: intl.getHTML('FREE.CALCULATOR.TESTIMONIALS.PAYMENT'),
                shareText: intl.get('CALCULATOR.SOCIAL-MESSAGE', {
                  currency: '',
                  url,
                }),
                useCase: 'preserve',
              },
            },
            {
              text: intl.get('FREE.CALCULATOR.TAB-TWO'),
              calculator: {
                sourceSelectText: intl.get('FREE.CALCULATOR.SOURCE-LABEL'),
                sourceInputText: intl.get('FREE.CALCULATOR.SOURCE-INPUT'),
                sourceOptions: this.props.rates.giftCards,
                targetInputText: intl.get('FREE.CALCULATOR.TARGET-INPUT'),
                targetOptions: false,
                image: giftCardsAvatar,
                text: intl.getHTML('FREE.CALCULATOR.TESTIMONIALS.GIFT-CARD'),
                shareText: intl.get('CALCULATOR.SOCIAL-MESSAGE', {
                  currency: '',
                  url,
                }),
                useCase: 'free',
              },
            },
            {
              text: intl.get('FREE.CALCULATOR.TAB-THREE'),
              calculator: {
                sourceSelectText: intl.get('FREE.CALCULATOR.SOURCE-LABEL'),
                sourceInputText: intl.get('FREE.CALCULATOR.SOURCE-INPUT'),
                sourceOptions: this.props.rates.giftCards,
                targetInputText: intl.get('FREE.CALCULATOR.TARGET-INPUT'),
                targetOptions: false,
                image: paymentProcessorsAvatar,
                text: intl.getHTML('FREE.CALCULATOR.TESTIMONIALS.MOBILE'),
                shareText: intl.get('CALCULATOR.SOCIAL-MESSAGE', {
                  currency: '',
                  url,
                }),
                useCase: 'preserve',
              },
            },
          ]}
        />
        }

        <div className="free-linear-background">
          <div className="container free-advantage-container">
            <div className="row center-xs">
              <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
                <Advantage
                  image={currencyAdvantage}
                  title={intl.get('FREE.ADVANTAGES.ITEM-ONE.TITLE')}
                  description={intl.get('FREE.ADVANTAGES.ITEM-ONE.DESCRIPTION')}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
                <Advantage
                  image={worldAdvantage}
                  title={intl.get('FREE.ADVANTAGES.ITEM-TWO.TITLE')}
                  description={intl.get('FREE.ADVANTAGES.ITEM-TWO.DESCRIPTION')}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
                <Advantage
                  image={clockAdvantage}
                  title={intl.get('FREE.ADVANTAGES.ITEM-THREE.TITLE')}
                  description={intl.get(
                    'FREE.ADVANTAGES.ITEM-THREE.DESCRIPTION',
                  )}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
                <Advantage
                  image={cloudAdvantage}
                  title={intl.get('FREE.ADVANTAGES.ITEM-FOUR.TITLE')}
                  description={intl.get(
                    'FREE.ADVANTAGES.ITEM-FOUR.DESCRIPTION',
                  )}
                />
              </div>
            </div>
          </div>

          <div className="testi-container">
            <TestimonialsCarousel
              testimonials={[
                {
                  name: 'Sergio Salas',
                  testimonial: intl.get('TESTIMONIALS.FREE.ONE'),
                  image: Man7,
                  fiveStars: false,
                },
                {
                  name: 'Damarys Ramírez',
                  testimonial: intl.get('TESTIMONIALS.FREE.TWO'),
                  image: Woman1,
                  fiveStars: true,
                },
                {
                  name: 'Isaac Perez',
                  testimonial: intl.get('TESTIMONIALS.FREE.THREE'),
                  image: Man5,
                  fiveStars: true,
                },
              ]}
            />
          </div>

          <div className="free-how-start-container">
            <HowStart title={intl.get('HOW-START.TITLE')} />
          </div>

          <BlogPostsSection
            posts={posts}
          />
        </div>

        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps)(Free);
