import * as React from 'react';
import './Tooltip.css';

type Props = {
  text: string,
  children: React.node,
  className?: string,
  styles: Object,
}

const tooltip = (props: Props) => (
  <div className={`tooltip ${props.className}`} style={props.styles}>
    {props.children}
    <span className="tooltip__badge">{props.text}</span>
  </div>
);

tooltip.defaultProps = {
  text: 'Tooltip',
};

export default tooltip;
