import {
  combineReducers,
  createStore,
  applyMiddleware,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import airtmSagas from './services/airtm/sagas';
import airtm from './services/airtm/reducer';

const reducers = combineReducers({
  airtm,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(airtmSagas);

export default store;
