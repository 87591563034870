import numeral from 'numeral';
import {
  ACTION_GET_FEED_FIELD,
  ACTION_GET_FEED_SUCCESS,
  ACTION_GET_FEED_ERROR,
} from './constants';


export default (state = {
  operations: {
    value: '1,500,000',
    loading: false,
  },
  users: {
    value: '+4,000',
    loading: false,
  },
  methods: {
    value: '+200',
    loading: false,
  },
  countries: {
    value: '163',
    loading: false,
  },
}, action) => {
  switch (action.type) {
    case ACTION_GET_FEED_FIELD:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          loading: true,
        },
      };
    case ACTION_GET_FEED_SUCCESS:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          value: numeral(action.count).format('0,0'),
          loading: false,
        },
      };
    case ACTION_GET_FEED_ERROR:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          loading: false,
        },
      };
    default:
      return state;
  }
};
