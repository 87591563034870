// @flow
import React from 'react';
import intl from 'react-intl-universal';
import Slider from 'react-slick';
import Testimonial from '../Testimonial/Testimonial';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import trustpilot from '../../assets/images/trustpilot.png';
import './TestimonialsCarousel.css';

type TestimonialInfo = {
  name: string,
  testimonial: string,
  image: string,
  fiveStars: boolean,
};

type Props = {
  testimonials: Array<TestimonialInfo>,
  infinite: boolean,
  slidesToShow: number,
  slidesToScroll: number,
  speed: number,
  dots: boolean,
  arrows: boolean,
};

const TestimonialsCarousel = (props: Props) => {
  const settings = {
    dots: props.dots,
    infinite: props.infinite,
    autoplay: true,
    dotsClass: 'testimonial-carousel__dots',
    speed: props.speed,
    arrows: props.arrows,
    slidesToShow: props.slidesToShow,
    slidesToScroll: props.slidesToScroll,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-carousel">
      <div className="container">
        <div className="testimonial-carousel__title">
          <h3>{intl.get('TESTIMONIALS.TITLE')}</h3>
        </div>
        <Slider {...settings}>
          {props.testimonials.map((testimonial, index) => (
            <div key={index}>
              <Testimonial
                name={testimonial.name}
                testimonial={testimonial.testimonial}
                image={testimonial.image}
                fiveStars={testimonial.fiveStars}
              />
            </div>
          ))}
        </Slider>
        <div className="row center-xs middle-xs trustpilot">
          <div className="col">
            <p className="testimonial-carousel__know-more-text">
              {intl.get('TESTIMONIALS.SUB-TEXT')}
            </p>
          </div>
          <div className="col">
            <a
              href="https://www.trustpilot.com/review/airtm.io?languages=all&stars=4&stars=5"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={trustpilot} height="24px" width="120px" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

TestimonialsCarousel.defaultProps = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  speed: 500,
  dots: true,
  arrows: true,
};

export default TestimonialsCarousel;
