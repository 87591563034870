// @flow
import React from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import AirtmLogo from '../../assets/images/airtm/alone.svg';
import YouTube from '../../assets/images/social/you-tube.svg';
import Medium from '../../assets/images/social/medium.svg';
import Twitter from '../../assets/images/social/twitter.svg';
import Facebook from '../../assets/images/social/facebook.svg';
import './Footer.css';

type Props = {};

type State = {};

class Footer extends React.Component<Props, State> {
  render() {
    return (
      <footer className="footer">
        <div className="footer__container">
          <div className="footer__logo-and-terms">
            <div className="footer__logo-and-terms-logo">
              <Link to="/" dangerouslySetInnerHTML={{ __html: AirtmLogo }} />
            </div>
            <p className="footer__logo-and-terms-terms">
              <Link to="/legal">
                {intl.get('FOOTER.TERMS')}
              </Link>
            </p>
            <p className="footer__logo-and-terms-phone">
              {intl.get('FOOTER.PHONE')}: +1 (415) 214 8737
            </p>
            <p className="footer__logo-and-terms-love">
              {intl.get('FOOTER.MADE-IN')}
            </p>
            <div className="footer__logo-and-terms-social">
              <a
                href="https://www.youtube.com/channel/UCy4pTghteONcuyvNKaR9DCg"
                className="footer__logo-and-terms-social-link"
                dangerouslySetInnerHTML={{ __html: YouTube }}
              />
              <a
                href="https://medium.com/@airtm"
                className="footer__logo-and-terms-social-link"
                dangerouslySetInnerHTML={{ __html: Medium }}
              />
              <a
                href="https://twitter.com/theairtm"
                className="footer__logo-and-terms-social-link"
                dangerouslySetInnerHTML={{ __html: Twitter }}
              />
              <a
                href="https://www.facebook.com/airtmLatAm"
                className="footer__logo-and-terms-social-link"
                dangerouslySetInnerHTML={{ __html: Facebook }}
              />
            </div>
          </div>
          <div className="footer__spacer" />
          <div className="footer__links">
            <p className="footer__links-title">{intl.get('NAVBAR.SERVICES')}</p>
            <Link to="/save" className="footer__links-link">
              {intl.get('NAVBAR.SAVE-IN-DOLLARS')}
            </Link>
            <Link to="/send" className="footer__links-link">
              {intl.get('NAVBAR.SEND-REMITTANCES')}
            </Link>
            <Link to="/free" className="footer__links-link">
              {intl.get('NAVBAR.FREE-YOUR-MONEY')}
            </Link>
            <a href="https://airtm.bitbucket.io" target="_blank" rel="noopener noreferrer" className="footer__links-link">
              Payments API
            </a>
          </div>
          <div className="footer__links">
            <p className="footer__links-title">{intl.get('FOOTER.COMPANY')}</p>
            <a href="https://blog.airtm.com/" target="_blank" rel="noopener noreferrer" className="footer__links-link">
              {intl.get('FOOTER.BLOG')}
            </a>
            <Link to="/about-us" className="footer__links-link">
              {intl.get('FOOTER.ABOUT-US')}
            </Link>
            <a
              href="https://angel.co/airtminc/jobs"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links-link"
            >
              {intl.get('FOOTER.JOBS')}
            </a>
            <a href="https://blog.airtm.com/press-partnerships" target="_blank" rel="noopener noreferrer" className="footer__links-link">
              {intl.get('FOOTER.PRESS')}
            </a>
          </div>
          <div className="footer__links">
            <p className="footer__links-title">{intl.get('FOOTER.HELP')}</p>
            <a
              href="http://help.airtm.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links-link"
            >
              FAQs
            </a>
            <a
              href="https://rates.airtm.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__links-link"
            >
              {intl.get('NAVBAR.RATES')}
            </a>
          </div>
        </div>
        <div className="footer__disclaimer">
          <p>{intl.get('FOOTER.DISCLAIMER')}</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
