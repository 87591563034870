// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import Arrow from '../../../assets/images/arrow.svg';
import './Dropdown.css';

type Props = {
  text: string,
  items: Array<Object>,
};

class Dropdown extends React.Component<Props> {
  constructor() {
    super();
    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    const { items, text } = this.props;
    const classes = ['dropdown__text-icon'];
    const location = window.location.hash;
    const shouldHideCrypto = location.indexOf('save') >= 0;

    if (this.state.showMenu) {
      classes.push('rotate-icon');
    }

    return (
      <div className="dropdown">
        <div className="dropdown__text" onClick={this.showMenu}>
          <span className="dropdown__text-content">{text}</span>
          <span className={classes.join(' ')} dangerouslySetInnerHTML={{ __html: Arrow }}></span>
        </div>
        {this.state.showMenu ? (
          <div className="dropdown__menu">
            <div className="dropdown__menu__list">
              {items.map((item) => {
                const itemClasses = ['dropdown__menu__list-item'];
                if (shouldHideCrypto && item.link === '/cryptos') {
                  itemClasses.push('hidden');
                }

                return (
                  <Link to={item.link} className={itemClasses.join(' ')} key={item.key}>
                    {item.text}
                  </Link>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Dropdown;
