import React from 'react';
import intl from 'react-intl-universal';
import CircleTile from '../../../../components/CircleTile/CircleTile';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import Separator from '../../../../components/Separator/Separator';
import cashBlue from '../../../../assets/images/cash_blue.svg';
import Modal from '../../../../components/Modal/Modal';
import './FreeBrief.css';

type Props = {
  text: string,
  firstTitle: string,
  firstTiles?: Array,
  secondTitle: string,
  secondTiles?: Array,
  thridTitle: string,
  thridTiles?: Array,
};

const freeBrief = (props:Props) => {
  let firstTiles = null;
  let secondTiles = null;
  let thridTiles = null;

  if (props.firstTiles && props.firstTiles.length > 0) {
    firstTiles = props.firstTiles.map((tile, index) => (
      <Tooltip className="tile-grid__tile-small" text={tile.tooltip} key={index}>
        <CircleTile name={tile.icon} size={100}/>
      </Tooltip>
    ));
  }

  if (props.secondTitle && props.secondTitle.length > 0) {
    secondTiles = props.secondTiles.map((tile, index) => (
      <Tooltip className="tile-grid__tile-small" text={tile.tooltip} key={index}>
        <CircleTile name={tile.icon} size={100}/>
      </Tooltip>
    ));
  }

  if (props.thridTiles && props.thridTiles.length > 0) {
    thridTiles = props.thridTiles.map((tile, index) => (
      <Tooltip className="tile-grid__tile-small" text={tile.tooltip} key={index}>
        <CircleTile name={tile.icon} size={100}/>
      </Tooltip>
    ));
  }

  return (
    <div className="freeBrief">
      <Separator image={cashBlue} />
      <div className="container freeBrief__container">
        <div className="freeBrief__container-text">
          <p>{props.text}</p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="freeBrief__container-title">
              <span>{props.firstTitle}</span>
            </div>
            <div className="freeBrief__container-column">
              {firstTiles}
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="freeBrief__container-title">
              <span>{props.secondTitle}</span>
            </div>
            <div className="freeBrief__container-column">
              {secondTiles}
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="freeBrief__container-title">
              <span>{props.thridTitle}</span>
            </div>
            <div className="freeBrief__container-column">
              {thridTiles}
            </div>
          </div>
        </div>
        <div className="freeBrief__container-subtext">
            <Modal text={intl.getHTML('FREE.MORE')} />
        </div>
      </div>
    </div>
  );
};

export default freeBrief;
