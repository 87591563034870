// @flow
import React from 'react';
import './InvestorBadge.css';

type Props = {
  image: string,
  investor: string,
  description: string,
};

const InvestorBadge = (props: Props) => (
  <div className="investor">
    <img src={props.image} alt="" className="investor__img" />
    <p className="investor__name"> {props.investor}</p>
    <p className="investor__description">{props.description}</p>
  </div>
);

export default InvestorBadge;
