import { combineReducers } from 'redux';
import {
  REQUEST_GET_RATES,
  RESPONSE_GET_RATES,
} from './rates/actionTypes';
import {
  REQUEST_GET_BLOG_POSTS,
  RESPONSE_GET_BLOG_POSTS,
  FAILURE_GET_BLOG_POSTS,
} from './blog/actionTypes';
import milestones from './milestones/reducer';


function rates(state = {
  loading: false,
  currencies: [],
  giftCards: [],
}, action) {
  switch (action.type) {
    case REQUEST_GET_RATES:
      return {
        ...state,
        loading: true,
      };
    case RESPONSE_GET_RATES:
      return {
        ...state,
        loading: false,
        currencies: action.currencies,
        giftCards: action.giftCards,
      };
    default:
      return state;
  }
}

function blog(state = {
  loading: false,
  blogPosts: [],
}, action) {
  switch (action.type) {
    case REQUEST_GET_BLOG_POSTS:
      return {
        ...state,
        loadingBlogPosts: true,
      };
    case RESPONSE_GET_BLOG_POSTS:
      return {
        ...state,
        blogPosts: action.blogPosts,
        loadingBlogPosts: false,
      };
    case FAILURE_GET_BLOG_POSTS:
      return {
        ...state,
        loadingBlogPosts: false,
        blogPosts: [],
      };
    default:
      return state;
  }
}


export default combineReducers({
  blog,
  rates,
  milestones,
});
