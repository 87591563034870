// @flow
import React from 'react';
import constants from '../../../constants';
import './Dropdown.css';

const { LOCAL_STORAGE } = constants;

type Props = {
  text: string,
  items: Array<Object>,
  icon: string,
};

class LanguagesDropdown extends React.Component<Props> {
  constructor() {
    super();
    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.onChangeLanguage = this.onChangeLanguage.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  onChangeLanguage(lang) {
    window.localStorage.setItem(LOCAL_STORAGE.LANGUAGE, lang);
    window.location.reload();
  }

  render() {
    const { items, text } = this.props;

    const classes = ['dropdown__text-icon'];

    if (this.state.showMenu) {
      classes.push('rotate-icon');
    }

    return (
      <div className="dropdown">
        <div className="dropdown__text" onClick={this.showMenu}>
          <span className="dropdown__text-content">{text}</span>
          <span className={classes.join(' ')} dangerouslySetInnerHTML={{ __html: this.props.icon }} />
        </div>
        {this.state.showMenu ? (
          <div className="dropdown__menu">
            <div className="dropdown__menu__list">
              {items.map(item => (
                <span
                  className="dropdown__menu__list-item"
                  key={item.key}
                  onClick={() => this.onChangeLanguage(item.key)}
                >
                  {item.text}
                </span>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default LanguagesDropdown;
