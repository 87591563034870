// @flow
import React from 'react';
import intl from 'react-intl-universal';
import MissionItem from '../MissionItem/MissionItem';
import './CompanyMission.css';
import coinsSVG from '../../../assets/images/company/mission-items/coins.svg';
import internetSVG from '../../../assets/images/company/mission-items/internet.svg';
import globeSVG from '../../../assets/images/company/mission-items/globe.svg';
import walletSVG from '../../../assets/images/company/mission-items/wallet.svg';

const CompanyMission = () => (
  <section className="container">
    <div className="company-mission__container">
      <div className="company-mission__heading">
        <p className="company-mission__title">{intl.get('COMPANY.AIRTM-ABOUT.TITLE')}</p>
        <p className="company-mission__subtitle">{intl.get('COMPANY.AIRTM-ABOUT.SUB-TITLE')}</p>
      </div>
    </div>

    <br />

    <div className="mission-items-container">
      <div className="row">
        <div className="col-12 col-sm-6">
          <MissionItem
            text={intl.get('COMPANY.AIRTM-ABOUT.ITEMS-TITLE.TITLE-ONE')}
            image={coinsSVG}
          />
        </div>
        <div className="col-12 col-sm-6">
          <MissionItem
            text={intl.get('COMPANY.AIRTM-ABOUT.ITEMS-TITLE.TITLE-TWO')}
            image={internetSVG}
          />
        </div>
        <div className="col-12 col-sm-6">
          <MissionItem
            text={intl.get('COMPANY.AIRTM-ABOUT.ITEMS-TITLE.TITLE-THREE')}
            image={globeSVG}
          />
        </div>
        <div className="col-12 col-sm-6">
          <MissionItem
            text={intl.get('COMPANY.AIRTM-ABOUT.ITEMS-TITLE.TITLE-FOUR')}
            image={walletSVG}
          />
        </div>
      </div>
    </div>
  </section>
);

export default CompanyMission;
