import React from 'react';
import intl from 'react-intl-universal';
import Select from 'Components/Select/Select';
import ShouldRender from 'Components/ShouldRender/ShouldRender';
import './Calculator.css';
import Icons from '../../utils/Icons';

type Props = {
  sourceSelectText?: string,
  sourceInputText?: string,
  sourceOptions?: Array<Object>,
  targetSelectText?: string,
  targetInputText?: string,
  targetOptions?: Array<Object>,
  text?: string,
  image?: string,
  shareText?: string,
  useCase?: string,
};

type State = {
  source: Object,
  target: Object,
  value: string,
  convertedValue: string,
  sourceQuery: string,
  targetQuery: string,
  filteredConversionOptions: Array,
};

class Calculator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      source: {},
      target: {},
      value: '0',
      sourceQuery: '',
      targetQuery: '',
      convertedValue: '0',
      filteredOptions: [],
    };

    this.sourceChanged = this.sourceChanged.bind(this);
    this.targetChanged = this.targetChanged.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.sourceQueryChanged = this.sourceQueryChanged.bind(this);
    this.targetQueryChanged = this.targetQueryChanged.bind(this);
    this.onConvertedValueChange = this.onConvertedValueChange.bind(this);
  }

  componentDidMount() {
    if (this.props.sourceOptions.length > 0) {
      const value = this.props.sourceOptions.find(
        currency => currency.Code === 'VES',
      );

      const [source] = this.props.sourceOptions;

      const newState = {};
      if (value) {
        newState.source = value;
      } else {
        newState.source = source;
      }

      if (!this.props.targetOptions) {
        newState.value = 20000;
      }

      this.setState(newState, () => {
        if (!this.props.targetOptions) {
          const convertedValue = this.calculateConvertedValue();
          this.setState({ convertedValue });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sourceOptions !== this.props.sourceOptions
      && this.props.sourceOptions.length > 0) {
      const value = this.props.sourceOptions.find(
        currency => currency.Code === 'VES',
      );

      const [source] = this.props.sourceOptions;
      const newState = {};
      if (value) {
        newState.source = value;
      } else {
        newState.source = source;
      }

      if (!this.props.targetOptions) {
        newState.value = 20000;
      }

      this.setState(newState, () => {
        if (!this.props.targetOptions) {
          const convertedValue = this.calculateConvertedValue();
          this.setState({ convertedValue });
        }
      });
    }

    if (prevState.source !== this.state.source && this.props.targetOptions) {
      const filtered = this.props.targetOptions.filter(
        option => option.Code !== this.state.source.Code,
      );

      const target = (this.state.target.Code && this.state.source.Code
        !== this.state.target.Code) ? this.state.target : filtered[0];

      this.setState({ filteredOptions: filtered, target }, () => {
        this.setState({ value: 20000 }, () => {
          const convertedValue = this.calculateConvertedValue();
          this.setState({ convertedValue });
        });
      });
    }
  }

  calculateConvertedValue() {
    let value;
    switch (this.props.useCase) {
      case 'preserve':
        value = Number(this.state.value) / Number(this.state.source.Rate);
        break;
      case 'send':
        value = Number(this.state.value) / Number(this.state.source.Buy);
        value *= this.state.target.Sell;
        break;
      case 'free':
        value = Number(this.state.value * this.state.source.Sell);
        break;
      default:
        value = 0;
    }

    return value;
  }

  calculateBaseValue() {
    let value;
    switch (this.props.useCase) {
      case 'preserve':
        value = Number(this.state.convertedValue)
          * Number(this.state.source.Rate);
        break;
      case 'send':
        value = Number(this.state.convertedValue)
          / Number(this.state.target.Sell);
        value *= this.state.source.Buy;
        break;
      case 'free':
        value = Number(this.state.value * this.state.source.Buy);
        break;
      default:
        value = 0;
    }

    return value;
  }

  sourceChanged(value) {
    this.setState({ source: value, targetQuery: '', sourceQuery: '' }, () => {
      const convertedValue = this.calculateConvertedValue();
      this.setState({ convertedValue });
    });
  }

  targetChanged(value) {
    this.setState({ target: value }, () => {
      const convertedValue = this.calculateConvertedValue();
      this.setState({ convertedValue });
    });
  }

  onValueChange(event) {
    const { value } = event.target;

    this.setState({ value }, () => {
      const convertedValue = this.calculateConvertedValue();
      this.setState({ convertedValue });
    });
  }

  onConvertedValueChange(event) {
    const { value } = event.target;

    this.setState({ convertedValue: value }, () => {
      const baseValue = this.calculateBaseValue();
      this.setState({ value: baseValue });
    });
  }

  formatShareText() {
    const currency = this.state.source;
    const url = window.location.href.replace(/#/, '%23');
    if (this.props.shareText) {
      return intl.get(this.props.shareText, {
        currency: `${currency.Rate} ${currency.Code}`,
        url,
      });
    }

    return '';
  }

  sourceQueryChanged({ target: { value } }) {
    this.setState({ sourceQuery: value });
  }

  targetQueryChanged({ target: { value } }) {
    this.setState({ targetQuery: value });
  }

  getSourceOptions() {
    return (this.state.sourceQuery !== '')
      ? this.props.sourceOptions.filter(
        option => option.Name.toLowerCase().indexOf(this.state.sourceQuery)
          > -1,
      )
      : this.props.sourceOptions;
  }

  getTargetOptions() {
    return (this.state.targetQuery !== '')
      ? this.state.filteredOptions.filter(
        option => option.Name.toLowerCase().indexOf(this.state.targetQuery)
          > -1,
      )
      : this.state.filteredOptions;
  }

  render() {
    const converterColumnSize = (this.props.targetOptions)
      ? { left: 'col-md-6 col-xs-12', right: 'col-md-6 col-xs-12' }
      : { left: 'col-md-8 col-xs-12', right: 'col-md-4 col-xs-12' };

    const { source, target } = this.state;
    const leftRate = `1 ${source.Code}`;
    let rate = (1 / source.Buy * target.Sell).toFixed(2);
    if (this.props.useCase === 'preserve') {
      rate = `${(1 / source.Rate).toFixed(2)} ${target.Code}`;
    }
    const rightRate = `${rate} ${target.Code}`;
    const url = window.location.href;

    return (
      <div className="calculator">

        <div className="calculator__message">
          <div className="row">
            <div className="col-md-1 col-xs-12 center-xs calculator__message-image">
              <img src={this.props.image} alt="" />
            </div>
            <div className="col-md-11 col-xs-12 center-xs calculator__message-text">
              {this.props.text}
            </div>
          </div>
        </div>

        <div className="calculator__converter">
          <div className="row">

            <div
              className={`calculator__converter-left ${converterColumnSize.left}`}
            >
              <div>

                <div className="row">
                  <div className="col-md col-xs-12 center-xs">
                    <div className="calculator__converter-label">
                      {this.props.sourceSelectText}
                    </div>
                    <div className="calculator__converter-content">
                      <Select
                        options={this.getSourceOptions()}
                        value={this.state.source}
                        query={this.state.sourceQuery}
                        onChange={this.sourceChanged}
                        onQueryChange={this.sourceQueryChanged}
                      />
                    </div>
                  </div>

                  <div className="col-md col-xs-12 center-xs">
                    <div className="calculator__converter-label">
                      {this.props.sourceInputText}
                    </div>
                    <div className="calculator__converter-content">

                      <div className="styled-input">
                        <div className="styled-input__main">
                          <div className="styled-input__left-text">$</div>
                          <input
                            className="styled-input__input"
                            type="number"
                            min="0"
                            value={this.state.value}
                            onChange={this.onValueChange}
                          />
                          <div className="styled-input__right-text">
                            {this.state.source.Code}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div
              className={`calculator__converter-right ${converterColumnSize.right}`}
            >
              <div>

                <div className="row">
                  <ShouldRender
                    condition={Boolean(this.props.targetOptions)}>
                    <div className="col-md-6">
                      <div className="calculator__converter-label">
                        {this.props.targetSelectText}
                      </div>
                      <div className="calculator__converter-content">
                        <Select
                          clear
                          options={this.getTargetOptions()}
                          value={this.state.target}
                          query={this.state.sourceQuery}
                          onChange={this.targetChanged}
                          onQueryChange={this.targetQueryChanged}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="calculator__converter-label">
                        {this.props.targetInputText}
                      </div>
                      <div className="calculator__converter-content">

                        <div className="styled-input styled-input--clear">
                          <div className="styled-input__main">
                            <div className="styled-input__left-text">$</div>
                            <input
                              className="styled-input__input"
                              type="number"
                              min="0"
                              value={this.state.convertedValue}
                              onChange={this.onConvertedValueChange}
                            />
                            <div className="styled-input__right-text">
                              {this.state.target.Code}
                            </div>
                          </div>
                          <div className="styled-input__description">
                            {leftRate} = {rightRate}
                          </div>
                        </div>

                      </div>
                    </div>
                  </ShouldRender>

                  <ShouldRender
                    condition={Boolean(!this.props.targetOptions)}>
                    <div className="col-md-12 col-xs-12">
                      <div className="calculator__converter-label">
                        {this.props.targetInputText}
                      </div>
                      <div className="calculator__converter-content">

                        <div className="styled-input styled-input--clear">
                          <div className="styled-input__main">
                            <div className="styled-input__left-text">$</div>
                            <input
                              className="styled-input__input"
                              type="number"
                              min="0"
                              value={this.state.convertedValue}
                              onChange={this.onConvertedValueChange}
                            />
                            <div className="styled-input__right-text">AirUSD
                            </div>
                          </div>
                          <div className="styled-input__description">
                            $1.00 AirUSD =
                            ${this.state.source.Rate}
                            {` ${this.state.source.Code}`}
                          </div>
                        </div>

                      </div>
                    </div>
                  </ShouldRender>
                </div>

                <div className="calculator__footer">
                  <div className="calculator__footer-share">
                    <div className="calculator__footer-text">Compartir</div>
                    <a
                      href={`https://twitter.com/intent/tweet?text=${this.formatShareText()}`}
                      className="calculator__footer-icon"
                      dangerouslySetInnerHTML={{ __html: Icons.twitter }}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                    <a
                      href={`https://www.facebook.com/dialog/share?app_id=1835738556671007&display=popup&href=${url}&redirect_uri=${url}&quote=${this.formatShareText()}`}
                      className="calculator__footer-icon"
                      dangerouslySetInnerHTML={{ __html: Icons.facebook }}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Calculator.defaultProps = {
  sourceOptions: [
    { icon: 'ali-pay', text: 'Ali Pay' },
    { icon: 'mercadopago', text: 'Mercado Pago' },
    { icon: 'neteller', text: 'Neteller' },
    { icon: 'payeer', text: 'Payeer' },
    { icon: 'paypal', text: 'Paypal' },
    { icon: 'perfect-money', text: 'Perfect Money' },
    { icon: 'skrill', text: 'Skrill' },
    { icon: 'tenpay', text: 'Tenpay' },
    { icon: 'uphold', text: 'Uphold' },
  ],
  targetOptions: [
    { icon: 'ali-pay', text: 'Ali Pay' },
    { icon: 'mercadopago', text: 'Mercado Pago' },
    { icon: 'neteller', text: 'Neteller' },
    { icon: 'payeer', text: 'Payeer' },
    { icon: 'paypal', text: 'Paypal' },
    { icon: 'perfect-money', text: 'Perfect Money' },
    { icon: 'skrill', text: 'Skrill' },
    { icon: 'tenpay', text: 'Tenpay' },
    { icon: 'uphold', text: 'Uphold' },
  ],
};

export default Calculator;
