// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import LogoAirTM from '../../../assets/images/airtm/alone.svg';
import './Brand.css';

type Props = {};

type State = {};

class Brand extends React.Component<Props, State> {
  render() {
    return (
      <div className="navigation__brand">
        <Link className="navigation__brand-link" to="/">
          <div className="navigation__brand-logo" dangerouslySetInnerHTML={{ __html: LogoAirTM }} />
        </Link>
      </div>
    );
  }
}

export default Brand;
