// @flow
import React from 'react';
import intl from 'react-intl-universal';
import constants from '../../constants';
import AuthContext from '../../context/AuthState';
import IconBurger from '../../assets/images/airtm/blue.svg';
import IconClose from '../../assets/images/close.svg';
import ArrowOne from '../../assets/images/arrow.svg';
import ArrowTwo from '../../assets/images/arrow-two.svg';
import Brand from './Brand/Brand';
import NavLink from './NavLink/NavLink';
import Dropdown from './Dropdown/Dropdown';
import LanguagesDropdown from './Dropdown/LanguagesDropdown';
import Button from '../Button/Button';
import { getLoginUrl, getSignUpUrl } from '../../utils/airtm/getAuthUrls';
import './Navbar.css';

const { LOCAL_STORAGE } = constants;

class Navbar extends React.Component<{
  authState: string,
}> {
  constructor() {
    super();
    this.state = {
      isHidden: true,
      loginUrl: '',
      signUpUrl: '',
    };

    this.toggleHidden = this.toggleHidden.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    // const state = this.props.authState;
    const loginUrl = getLoginUrl();
    const signUpUrl = getSignUpUrl();
    this.setState({
      loginUrl,
      signUpUrl,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollPosition = window.pageYOffset;
    const element = document.getElementById('navigation');
    const app = document.getElementById('app');

    if (scrollPosition > 60) {
      element.classList.add('navigation-sticky');
      app.classList.add('mt-80');
    } else {
      element.classList.remove('navigation-sticky');
      app.classList.remove('mt-80');
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    const itemsServices = [
      {
        key: 0,
        text: intl.get('NAVBAR.SAVE-IN-DOLLARS'),
        link: '/save',
      },
      {
        key: 1,
        text: intl.get('NAVBAR.SEND-REMITTANCES'),
        link: '/send',
      },
      {
        key: 2,
        text: intl.get('NAVBAR.FREE-YOUR-MONEY'),
        link: '/free',
      },
    ];

    const languagesItems = [
      {
        key: 'es',
        text: 'ES',
      },
      {
        key: 'en',
        text: 'EN',
      },
    ];

    const getCurrentLanguage = () => {
      const currentLanguage = window.localStorage.getItem(LOCAL_STORAGE.LANGUAGE);
      const item = languagesItems.filter(el => el.key === currentLanguage);
      return item[0].text;
    };

    const getIcon = () => {
      if (this.state.isHidden) {
        return IconBurger;
      }
      return IconClose;
    };

    const classes = ['navigation__icon'];

    if (this.state.isHidden === false) {
      classes.push('fixed');
    }

    return (
      <nav className="navigation" id="navigation">
        <div className="navigation__container container">
          <Brand />
          <div className="navigation__items">
            <a className="link" href="https://rates.airtm.com/" target="_blank" rel="noreferrer noopener">{intl.get('NAVBAR.RATES')}</a>
            <Dropdown text={intl.get('NAVBAR.SERVICES')} items={itemsServices} />
            <a className="link" href="https://blog.airtm.com/" target="_blank" rel="noreferrer noopener">{intl.get('NAVBAR.BLOG')}</a>
            <NavLink link="/" text={intl.get('NAVBAR.SIGNIN')} onClick={ () => { window.location.href = this.state.loginUrl; }} />
            <Button type="solid">{intl.get('BUTTONS.JOIN')}</Button>
            <LanguagesDropdown
              text={getCurrentLanguage()}
              items={languagesItems}
              icon={ArrowOne}
            />
          </div>
          <div className="lang-responsive">
            <LanguagesDropdown
              text={getCurrentLanguage()}
              items={languagesItems}
              icon={ArrowTwo}
            />
          </div>
          <div
            className={classes.join(' ')}
            onClick={this.toggleHidden.bind(this)}
            dangerouslySetInnerHTML={{ __html: getIcon() }}
          />
          {
            !this.state.isHidden
            && (
              <NavResponsive
                onClick={this.toggleHidden.bind(this)}
                login={ () => { window.location.href = this.state.loginUrl; }}
                signUp={ () => { window.location.href = this.state.signUpUrl; }}
              />
            )
          }
        </div>
      </nav>
    );
  }
}

type Props = {
  onClick: Function,
  login: Function,
  signUp: Function,
};

const NavResponsive = (props : Props) => (
  <div className="navigation__items-responsive">
    <Brand />
    <NavLink link="/" text={intl.get('NAVBAR.SIGNIN')} onClick={props.login} />
    <NavLink link="/" text={intl.get('BUTTONS.JOIN')} onClick={props.signUp} />
    <hr className="navigation__line" />
    <NavLink link="/save" text={intl.get('NAVBAR.SAVE-IN-DOLLARS')} onClick={props.onClick} />
    <NavLink link="/send" text={intl.get('NAVBAR.SEND-REMITTANCES')} onClick={props.onClick} />
    <NavLink link="/free" text={intl.get('NAVBAR.FREE-YOUR-MONEY')} onClick={props.onClick} />
    <hr className="navigation__line" />
    <div className="navigation__items-item">
      <a target="_blank" rel="noreferrer noopener" href="https://blog.airtm.com/" onClick={props.onClick} >{intl.get('NAVBAR.BLOG')}</a>
    </div>
    <div className="navigation__items-item">
      <a href="https://rates.airtm.com/" target="_blank" rel="noreferrer noopener">{intl.get('NAVBAR.RATES')}</a>
    </div>
    <NavLink link="/about-us" text={intl.get('FOOTER.ABOUT-US')} onClick={props.onClick} />
  </div>
);

export default function StateConsumerNavbar(props) {
  return (
    <AuthContext.Consumer>
    {
      authState => <Navbar authState={authState} {...props} />
    }
    </AuthContext.Consumer>
  );
}
