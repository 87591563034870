import React from 'react';
import BlogPost from '../BlogPost/BlogPost';
import './BlogPostsSection.css';

type PostInfo = {
  image?: string,
  category: string,
  categoryColor?: string,
  title: string,
  excerpt: string,
  link: string,
};

type Props = {
  posts: Array<PostInfo>,
};

const BlogPostsSection = (props: Props) => {
  if (props.posts.length !== 3) {
    return (<div></div>);
  }
  return (<div className="container blog-posts-container">
    <div className="row center-xs">
      <div className="col-xs-12">
        <p className="blog-posts-title">
          Para aprender a sacarle el mayor provecho a tu dinero, visita nuestro blog:
        </p>
      </div>
      {props.posts.map((post, index) => (
        <div key={index} className="col col-sm-6 col-md-4">
          <BlogPost
            image={post.image}
            category={post.category}
            categoryColor={post.categoryColor}
            title={post.title}
            excerpt={post.excerpt}
            link={post.link}
          />
        </div>
      ))}
    </div>
  </div>
  );
};

export default BlogPostsSection;
