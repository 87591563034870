// @flow
import React from 'react';
import intl from 'react-intl-universal';
import './Map.css';
import map from '../../assets/images/map.svg';

type Props = {};

type State = {};

class Map extends React.Component<Props, State> {
  componentDidMount() {
    this.transactionsGroup = document.querySelector('#transactions-group');
    this.transactions = [];

    for (let index = 1; index <= 16; index += 1) {
      this.transactions.push(this.transactionsGroup.querySelector(`#transaction-${index}`));
    }

    this.hiddeAllTransactions();
    this.setRandomClass();

    setInterval(() => {
      this.hiddeAllTransactions();
      this.setRandomClass();
    }, 1200);
  }

  hiddeAllTransactions() {
    this.transactions.forEach((transaction) => {
      transaction.classList.add('map__hidden-transaction');
      transaction.classList.remove('map__visible-transaction');
    });
  }

  setRandomClass() {
    const numberOfTransactions = 15;
    const randomIndex = Math.floor(Math.random() * (numberOfTransactions + 1));
    const transaction = this.transactions[randomIndex];
    const transactionText = transaction.querySelector('#transaction-title').querySelector('tspan');
    transactionText.innerHTML = intl.get(transactionText.dataset.textKey);
    transaction.classList.remove('map__hidden-transaction');
    transaction.classList.add('map__visible-transaction');
  }

  render() {
    return (
      <div className="map">
        <section className="container">
          <div className="map__heading">
            <h3 className="map__heading__text">{intl.getHTML('HOME.MAP.TITLE')}</h3>
          </div>
          <div className="map__image" dangerouslySetInnerHTML={{ __html: map }} />
        </section>
      </div>
    );
  }
}

export default Map;
