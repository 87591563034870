import Axios from 'axios';


export default function () {
  this.baseUrl = 'https://blog.airtm.com/wp-json/wp/v2';
  this.instance = Axios.create({
    baseURL: this.baseUrl,
  });

  return {
    fetchBlogPosts: async () => {
      try {
        const response = await this.instance.get('/posts');
        return response.data;
      } catch (error) {
        return [];
      }
    },
  };
}
