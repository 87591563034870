// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import './NavLink.css';

type Props = {
  text: string,
  link: string,
  className?: string,
  onClick: Function,
};

class NavLink extends React.Component<Props> {
  render() {
    return (
      <div className={`navigation__items-item ${this.props.className}`} onClick={this.props.onClick}>
        <Link to={this.props.link}>
          {this.props.text}
        </Link>
      </div>
    );
  }
}

export default NavLink;
