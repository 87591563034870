// @flow
import React, { Component } from 'react';
import classnames from 'classnames';
import Calculator from 'Components/Calculator/Calculator';
import ShouldRender from 'Components/ShouldRender/ShouldRender';
import './TabbedCalculator.css';

type Props = {
  useCase?: string,
  shareText?: string,
  tabs?: Array<Object>,
}

type State = {
  selected: string,
}

class TabbedCalculator extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selected: '',
    };
  }

  componentDidMount() {
    if (this.state.selected === '' && this.props.tabs.length > 0) {
      this.setState({ selected: this.props.tabs[0].text });
    }
  }

  componentDidUpdate() {
    if (this.state.selected === '' && this.props.tabs.length > 0) {
      this.setState({ selected: this.props.tabs[0].text });
    }
  }

  renderTabs() {
    return this.props.tabs.map((tab) => {
      if (tab.text === '') {
        return null;
      }
      return (
        <div
          className={classnames('tabbed-calculator__tabs__tab', {
            'tabbed-calculator__tabs__tab--active': this.state.selected
            === tab.text,
          })}
          key={tab.text}
          onClick={() => this.setState({ selected: tab.text })}
        >
          <div className="tabbed-calculator__tabs__tab__text">
            {tab.text}
          </div>
        </div>
      );
    });
  }

  renderCalculators() {
    return this.props.tabs.map((tab) => {
      if (tab.text === '') {
        return null;
      }

      return (
        <ShouldRender
          condition={this.state.selected === tab.text}
          key={tab.text}
        >
          <Calculator
            {...tab.calculator}
          />
        </ShouldRender>
      );
    });
  }

  render() {
    return (
      <div className="tabbed-calculator">
        <div className="tabbed-calculator__tabs">
          {this.renderTabs()}
        </div>
        <div className="tabbed-calculator__calculators">
          {this.renderCalculators()}
        </div>
      </div>
    );
  }
}

export default TabbedCalculator;
