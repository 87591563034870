// @flow
import React from 'react';
import InvestorBadge from '../../../components/InvestorBadge/InvestorBadge';
import IgniaLogo from '../../../assets/images/company/investors/ignia.png';
import BankLogo from '../../../assets/images/company/investors/banktothefuture.png';
import BlueYardLogo from '../../../assets/images/company/investors/blueyard.png';
import './OurInvestors.css';

type Props = {
  heading: string,
};

const OurInvestors = (props: Props) => (
  <div className="our-investors container">
    <div className="row start-xs start-sm center-md">
      <div className="col-sm-12">
        <p className="our-investors__heading">{props.heading}</p>
      </div>
    </div>

    <div className="row center-xs">
      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <InvestorBadge
          image={BankLogo}
          investor="BanktotheFuture"
          description="Invested in @Bitstamp and @Krakenfx"
        />
      </div>

      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <InvestorBadge
          image={IgniaLogo}
          investor="Ignia"
          description="Invested in @Afluenta, @Arcusfi and @TiendaNube"
        />
      </div>

      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <InvestorBadge
          image={BlueYardLogo}
          investor="BlueYard"
          description="Invested in @MineFliecoin, @OB1Company and @ProtocolLabs"
        />
      </div>
    </div>
  </div>
);

export default OurInvestors;
