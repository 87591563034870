import { REQUEST_GET_RATES } from './rates/actionTypes';
import { REQUEST_GET_BLOG_POSTS } from './blog/actionTypes';


export function mapStateToProps(state) {
  return {
    ...state.airtm,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    getExchangeRates: () => dispatch({
      type: REQUEST_GET_RATES,
    }),
    getBlogPosts: () => dispatch({
      type: REQUEST_GET_BLOG_POSTS,
    }),
  };
}
