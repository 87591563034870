/* eslint-disable */
import constants from '../../constants';

const { LOCAL_STORAGE } = constants;

const getUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
};

export function getSignUpUrl() {
  const host = window.location.host.replace('www.', '');
  const lng = window.localStorage.getItem(LOCAL_STORAGE.LANGUAGE);
  const { search } = window.location;
  const q = search ? `${search}&lng=${lng}` : `?lng=${lng}`;
  const signUpUrl = `${window.location.protocol}//app.${host}/sign-up${q}`;
  return signUpUrl;
};

export function getLoginUrl() {
  const host = window.location.host.replace('www.', '');
  const lng = window.localStorage.getItem(LOCAL_STORAGE.LANGUAGE);
  const { search } = window.location;
  const q = search ? `${search}&lng=${lng}` : `?lng=${lng}`;
  const loginUrl = `${window.location.protocol}//app.${host}/login${q}`;
  return loginUrl;
}
