// @flow
import React from 'react';
import './CookieBar.css';

type Props = {
  text: string,
  buttonText: string,
  isHidden: boolean
};

class CookieBar extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.props = props;

    this.state = {
      isHidden: false,
    };

    this.toggleHidden = this.toggleHidden.bind(this);

    if (window.localStorage.getItem('acceptUseOfCookies') === null) {
      window.localStorage.setItem('acceptUseOfCookies', 'false');
    }

    if (window.localStorage.getItem('acceptUseOfCookies') === 'true') {
      this.state = {
        isHidden: true,
      };
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
    window.localStorage.setItem('acceptUseOfCookies', 'true');
  }

  displayBar(isHidden: boolean) {
    if (isHidden) {
      return <div />;
    }

    return (
      <div className="cookie-bar">
        <div className="row middle-xs cookie-bar__container">
          <div
            className="col-xs-12 col-sm-10"
            dangerouslySetInnerHTML={{ __html: this.props.text }}
          />
          <div className="col-xs-12 col-sm-2 center-xs">
            <span className="cookie-bar__button" onClick={this.toggleHidden}>
              {this.props.buttonText}
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.displayBar(this.state.isHidden);
  }
}

export default CookieBar;
