// @flow
import React from 'react';
import './Separator.css';
import cashImage from '../../assets/images/cash.png';

type Props = {
  image?: string,
};

function checkImageIsDefault(image: string) {
  if (image === 'images/cash.png') {
    return <img src={cashImage} alt="" className="separator__image" />;
  }
  return <div dangerouslySetInnerHTML={{ __html: image }} alt="" className="separator__image" />;
}

const Separator = (props: Props) => (
  <div className="separator">
    <div className="separator__line" />
    {checkImageIsDefault(props.image)}
    <div className="separator__line" />
  </div>
);

Separator.defaultProps = {
  image: 'images/cash.png',
};

export default Separator;
