import React from 'react';
import './HighlightedText.css';

type Props = {
  number: string,
  sub: string,
};

const HighlightedText = (props: Props) => (<div className="highlighted">
      <div className="highlighted__heading">
        <span>{props.number}</span>
      </div>
      <div className="highlighted__description">
        <span>{props.sub}</span>
      </div>
    </div>);


HighlightedText.defaultProps = {
  number: '0',
  sub: 'Sin descripción',
};

export default HighlightedText;
