// @flow
import React from 'react';
import intl from 'react-intl-universal';
import Button from '../../../../components/Button/Button';
import './HeaderHome.css';

type Props = {
  parragraph: string,
};

class HeaderHome extends React.Component<Props> {
  componentDidMount() {
    if (document.getElementById('carousel-words')) {
      setTimeout(this.triggerWordRotator, 1000);
    }
  }

  componentDidUpdate() {
    if (document.getElementById('carousel-words')) {
      this.triggerWordRotator();
    }
  }

  triggerWordRotator() {
    const words = document.getElementById('carousel-words');
    const wordCount = document.querySelectorAll('.word-carousel__word').length;
    const containerHeight = Number(
      window.getComputedStyle(words).height.replace(/px/, ''),
    );
    const elementHeight = Number(
      window.getComputedStyle(words.children[0]).height.replace(/px/, ''),
    ) + 10;

    const percentage = (elementHeight * 100) / containerHeight;
    for (let i = 1; i < wordCount; i += 1) {
      setTimeout(() => {
        words.style.transform = `translateY(${percentage * i}%)`;
      }, 2000 * i);
    }
  }

  render() {
    return (
      <div className="header">
        <div className="header__container container">
          <div className="header__heading">
            <h3 className="header__heading-text">
              <div>
                {intl.get('HOME.HEADER.TITLE')}
              </div>
              <div className="word-carousel">
                <div className="word-carousel__content">
                  <div id="carousel-words" className="word-carousel__words">
                    <div className="word-carousel__word">
                      {intl.get('HOME.HEADER.WORDS.FIVE')}
                    </div>
                    <div className="word-carousel__word">
                      {intl.get('HOME.HEADER.WORDS.FOUR')}
                    </div>
                    <div className="word-carousel__word">
                      {intl.get('HOME.HEADER.WORDS.THREE')}
                    </div>
                    <div className="word-carousel__word">
                      {intl.get('HOME.HEADER.WORDS.TWO')}
                    </div>
                    <div className="word-carousel__word">
                      {intl.get('HOME.HEADER.WORDS.ONE')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="word-carousel-responsive">
                <div className="word-carousel-responsive__content">
                  <div className="word-carousel-responsive__overlay" />
                  <div id="carousel-words-responsive" className="word-carousel-responsive__words">
                    <div className="word-carousel-responsive__word">
                      {intl.get('HOME.HEADER.WORDS.FIVE')}
                    </div>
                  </div>
                </div>
              </div>
            </h3>
          </div>
          <div className="header__parragraph">
            <p className="header__parragraph-text">{this.props.parragraph}</p>
          </div>
          <div className="header__input">
            <Button
              type="solid"
              link="https://auth.airtm.io/signup?redirect_uri=https://www.airtm.io/dashboard/new-setup&state=9e6d04c6-a846-48ff-9c7e-3fec1b292569"
            >
              {intl.get('BUTTONS.GET-STARTED')}
            </Button>
            <span className="header__input-subtext">
              {intl.get('INPUT.SUB-TEXT')}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderHome;
