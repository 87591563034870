import React from 'react';
import CircleTile from '../../../../components/CircleTile/CircleTile';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import './PaymentsMethods.css';

type Props = {
  tilesOne?: Array,
  tilesTwo?: Array,
  title: string,
  showIcons: number,
}

const PaymentsMethods = (props: Props) => {
  let tilesOne = null;
  let tilesTwo = null;

  if (props.tilesOne && props.tilesOne.length > 0) {
    tilesOne = props.tilesOne.map((tile, index) => (
      <Tooltip className="tile-grid__tile-small" text={tile.tooltip} key={index}>
        <CircleTile name={tile.icon} size={80}/>
      </Tooltip>
    ));
  }

  if (props.tilesTwo && props.tilesTwo.length > 0) {
    tilesTwo = props.tilesTwo.map((tile, index) => (
      <Tooltip className="tile-grid__tile-small" text={tile.tooltip} key={index}>
        <CircleTile name={tile.icon} size={80}/>
      </Tooltip>
    ));
  }

  return (
    <div className="payments">
      <div className="container-fluid">
        <h2 className="payments__title">{props.title}</h2>
      </div>
      <div className="payments__container">
        <div id="pay-one" className="payments__group-1">
          {tilesTwo}
        </div>
        <div id="pay-two" className="payments__group-2">
          {tilesOne}
        </div>
      </div>
    </div>
  );
};

export default PaymentsMethods;
