// @flow
import * as React from 'react';

type Props = {
  condition?: boolean,
  children?: React.Node,
};

const shouldRender = (props: Props) => {
  if (props.condition) {
    return props.children;
  }

  return null;
};

shouldRender.defaultProps = {
  condition: false,
};

export default shouldRender;
