// @flow
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import CalculatorSection from 'Components/CalculatorSection/CalculatorSection';
import BlogPostsSection from 'Components/BlogPostsSection/BlogPostsSection';
import HeaderSend from './components/HeaderSend/HeaderSend';
import PageBrief from '../../components/PageBrief/PageBrief';
import Advantage from '../../components/Advantage/Advantage';
import TestimonialsCarousel from '../../components/TestimonialsCarousel/TestimonialsCarousel';
import HowStart from '../../components/HowStart/HowStart';
import Footer from '../../components/Footer/Footer';
import './Send.css';
import currencyAdvantage from '../../assets/images/advantages/currency.png';
import worldAdvantage from '../../assets/images/advantages/world.png';
import clockAdvantage from '../../assets/images/advantages/clock.png';
import cloudAdvantage from '../../assets/images/advantages/cloud.png';
import Man6 from '../../assets/images/testimonials/m-6.png';
import Woman2 from '../../assets/images/testimonials/f-2.png';
import Man5 from '../../assets/images/testimonials/m-5.png';
import noPostImage from '../../assets/images/no-post-image.png';
import calculatorAvatar from '../../assets/images/calculator-avatar-send.png';
import { mapStateToProps } from '../../services/airtm/connect';

type Props = {
  rates: Array<Object>,
  blog?: Array<Object>,
};

class Send extends React.Component<Props> {
  render() {
    const countries = [
      { icon: 'argentina', tooltip: 'Argentina' },
      { icon: 'brazil', tooltip: 'Brasil' },
      { icon: 'chile', tooltip: 'Chile' },
      { icon: 'china', tooltip: 'China' },
      { icon: 'colombia', tooltip: 'Colombia' },
      { icon: 'india', tooltip: 'India' },
      { icon: 'germany', tooltip: 'Alemania' },
      { icon: 'greece', tooltip: 'Gracia' },
      { icon: 'honduras', tooltip: 'Honduras' },
      { icon: 'hongkong', tooltip: 'Hong-Kong' },
      { icon: 'iraq', tooltip: 'Iraq' },
      { icon: 'italia', tooltip: 'Italia' },
      { icon: 'japan', tooltip: 'Japón' },
      { icon: 'mexico', tooltip: 'México' },
      { icon: 'nigeria', tooltip: 'Nigeria' },
      { icon: 'panama', tooltip: 'Panama' },
      { icon: 'peru', tooltip: 'Perú' },
      { icon: 'portugal', tooltip: 'Portugal' },
      { icon: 'qatar', tooltip: 'Qatar' },
      { icon: 'sierraleona', tooltip: 'Sierra Leona' },
      { icon: 'southafrica', tooltip: 'South Africa' },
      { icon: 'spain', tooltip: 'España' },
      { icon: 'us', tooltip: 'Estados Unidos' },
      { icon: 'venezuela', tooltip: 'Venezuela' },
    ];

    const url = window.location.href.replace(/#/, '%23');

    let posts = [];

    if (this.props.blog.blogPosts) {
      const cryptosPosts = this.props.blog.blogPosts.filter(post => post.categories.includes(1) && post.better_featured_image && post.better_featured_image.media_details && post.better_featured_image.media_details.sizes && post.better_featured_image.media_details.sizes['et-pb-post-main-image']);
      posts = cryptosPosts.map(post => ({
        image: post.better_featured_image === null ? noPostImage : post.better_featured_image.media_details.sizes['et-pb-post-main-image'].source_url,
        category: 'Envía remesas',
        categoryColor: 'green',
        title: post.title.rendered,
        excerpt: post.excerpt.rendered,
        link: post.link,
      }));

      if (posts.length >= 3) {
        posts = posts.slice(0, 3);
      } else {
        posts = [];
      }
    }

    return (
      <div className="send">
        <HeaderSend title={intl.get('SEND.TITLE')} />
        <PageBrief
          text={intl.getHTML('SEND.SUB-TITLE')}
          tiles={countries}
          more={intl.getHTML('SEND.MORE')}
        />

        {false
        && <CalculatorSection
          title={intl.get('SEND.CALCULATOR.TITLE')}
          subtitle={intl.get('SEND.CALCULATOR.SUB-TITLE')}
          calculator={{
            sourceSelectText: intl.get('SEND.CALCULATOR.SOURCE-LABEL'),
            sourceInputText: intl.get('SEND.CALCULATOR.SOURCE-INPUT'),
            sourceOptions: this.props.rates.currencies,
            targetSelectText: intl.get('SEND.CALCULATOR.TARGET-LABEL'),
            targetInputText: intl.get('SEND.CALCULATOR.TARGET-INPUT'),
            targetOptions: this.props.rates.currencies,
            image: calculatorAvatar,
            text: intl.getHTML('SEND.CALCULATOR.TESTIMONIAL'),
            shareText: intl.get('CALCULATOR.SOCIAL-MESSAGE', { currency: '', url }),
            useCase: 'send',
          }}
        />
        }

        <div className="advantage-container container">
          <div className="row center-xs">
            <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
              <Advantage
                image={currencyAdvantage}
                title={intl.get('SEND.ADVANTAGES.ITEM-ONE.TITLE')}
                description={intl.get('SEND.ADVANTAGES.ITEM-ONE.DESCRIPTION')}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
              <Advantage
                image={worldAdvantage}
                title={intl.get('SEND.ADVANTAGES.ITEM-TWO.TITLE')}
                description={intl.get('SEND.ADVANTAGES.ITEM-TWO.DESCRIPTION')}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
              <Advantage
                image={clockAdvantage}
                title={intl.get('SEND.ADVANTAGES.ITEM-THREE.TITLE')}
                description={intl.get('SEND.ADVANTAGES.ITEM-THREE.DESCRIPTION')}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3" style={{ padding: '0', margin: '0' }}>
              <Advantage
                image={cloudAdvantage}
                title={intl.get('SEND.ADVANTAGES.ITEM-FOUR.TITLE')}
                description={intl.get('SEND.ADVANTAGES.ITEM-FOUR.DESCRIPTION')}
              />
            </div>
          </div>
        </div>
        <div className="testimonials-container">
          <TestimonialsCarousel
            testimonials={[
              {
                name: 'Miguel',
                testimonial: intl.get('TESTIMONIALS.SEND.ONE'),
                image: Man6,
                fiveStars: false,
              },
              {
                name: 'KarlysPDN',
                testimonial: intl.get('TESTIMONIALS.SEND.TWO'),
                image: Woman2,
                fiveStars: true,
              },
              {
                name: 'Victor',
                testimonial: intl.get('TESTIMONIALS.SEND.THREE'),
                image: Man5,
                fiveStars: false,
              },
            ]}
          />
        </div>
        <HowStart title={intl.get('HOW-START.TITLE')} />

        <BlogPostsSection
          posts={posts}
        />

        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps)(Send);
