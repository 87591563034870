// @flow
import React from 'react';
import TeamMemberBadge from '../../../components/TeamMemberBadge/TeamMemberBadge';

import adrianDruzgalskiImg from '../../../assets/images/company/members/adrian_druzgalski.jpg';
import albertoPickeringImg from '../../../assets/images/company/members/alberto_pickering.jpg';
import aldoFunesImg from '../../../assets/images/company/members/aldo_funes.jpg';
import alexZiehm from '../../../assets/images/company/members/alex_ziehm.jpg';
import alexiaAguirreImg from '../../../assets/images/company/members/alexia_aguirre.jpg';
import carlosFernandezImg from '../../../assets/images/company/members/carlos_fernandez.jpg';
import edgardMoralesImg from '../../../assets/images/company/members/edgard_morales.jpg';
import eduardoCruzImg from '../../../assets/images/company/members/eduardo_cruz.jpg';
import felixGarciaImg from '../../../assets/images/company/members/felix_garcia.jpg';
import freyAguilarImg from '../../../assets/images/company/members/frey_aguilar.jpg';
import javierEnriquezImg from '../../../assets/images/company/members/javier_enriquez.jpg';
import jeronimoBernotImg from '../../../assets/images/company/members/jeronimo_bernot.jpg';
import jonathanBadilloImg from '../../../assets/images/company/members/jonathan_badillo.jpg';
import jorgeDiazImg from '../../../assets/images/company/members/jorge_diaz.jpg';
import joseLuisCuri from '../../../assets/images/company/members/jose_luis_curi.jpg';
import joshKliotImg from '../../../assets/images/company/members/josh_kliot.jpg';
import kevinWooImg from '../../../assets/images/company/members/kevin_woo.jpg';
import lauraSanchezImg from '../../../assets/images/company/members/laura_sanchez.jpg';
import lunaPradaImg from '../../../assets/images/company/members/luna_prada.jpg';
import miguelMaganaImg from '../../../assets/images/company/members/miguel_magana.jpg';
import miguelValenciaImg from '../../../assets/images/company/members/miguel_valencia.jpg';
import pabloRoaImg from '../../../assets/images/company/members/pablo_roa.jpg';
import raulMartinezImg from '../../../assets/images/company/members/raul_martinez.jpg';
import raulSanchezImg from '../../../assets/images/company/members/raul_sanchez.jpg';
import rodrigoContrerasImg from '../../../assets/images/company/members/rodrigo_contreras.jpg';
import rubenGalindoImg from '../../../assets/images/company/members/ruben_galindo.jpg';
import sergioBarajasImg from '../../../assets/images/company/members/sergio_barajas.jpg';
import sergioHernandezImg from '../../../assets/images/company/members/sergio_hernandez.jpg';
import thomasBrookeImg from '../../../assets/images/company/members/thomas_brooke.jpg';
import timParsaImg from '../../../assets/images/company/members/tim_parsa.jpg';
import ulisesPuenteImg from '../../../assets/images/company/members/ulises_puente.jpg';

import './OurTeam.css';

type Props = {
  heading: string,
};

const people = [
  {
    image: rubenGalindoImg,
    name: 'Ruben Galindo',
    position: 'CEO, Co-founder',
  },
  {
    image: miguelValenciaImg,
    name: 'Miguel Valencia',
    position: 'COO',
  },
  {
    image: joshKliotImg,
    name: 'Josh Kliot',
    position: 'Product, Co-founder',
  },
  {
    image: timParsaImg,
    name: 'Tim Parsa',
    position: 'CCO, Co-founder',
  },
  {
    image: raulSanchezImg,
    name: 'Raul Sanchez',
    position: 'CFO',
  },
  {
    image: adrianDruzgalskiImg,
    name: 'Adrian Druzgalski',
    position: 'VP of Engineering',
  },
  {
    image: thomasBrookeImg,
    name: 'Thomas Brooke',
    position: 'Chief of Counsel',
  },
  {
    image: eduardoCruzImg,
    name: 'Eduardo Cruz',
    position: 'Head of partnerships',
  },
  {
    image: sergioBarajasImg,
    name: 'Sergio Barajas',
    position: 'Chief of Staff',
  },
  {
    image: alexiaAguirreImg,
    name: 'Alexia Aguirre',
    position: 'Head of Human Resources',
  },
  {
    image: jeronimoBernotImg,
    name: 'Jerónimo Bernot',
    position: 'Head of Customer Success',
  },
  {
    image: lunaPradaImg,
    name: 'Luna Prada',
    position: 'Compliance',
  },
  {
    image: alexZiehm,
    name: 'Alex Ziehm',
    position: 'Product Manager',
  },
  {
    image: pabloRoaImg,
    name: 'Pablo Roa',
    position: 'Risk and Fraud Leader',
  },
  {
    image: miguelMaganaImg,
    name: 'Miguel Magaña',
    position: 'Business Development',
  },
  {
    image: ulisesPuenteImg,
    name: 'Ulises Puente',
    position: 'Accounting Associate',
  },
  {
    image: joseLuisCuri,
    name: 'Jose Luis Curi',
    position: 'Finance',
  },
  {
    image: freyAguilarImg,
    name: 'Frey Aguilar',
    position: 'Compliance Risk Analyst',
  },
  {
    image: lauraSanchezImg,
    name: 'Laura Sanchez',
    position: 'Project Manager',
  },
  {
    image: felixGarciaImg,
    name: 'Felix Garcia',
    position: 'Lead Integrations Engineer',
  },
  {
    image: javierEnriquezImg,
    name: 'Javier Enriquez',
    position: 'Senior Software Architect',
  },
  {
    image: aldoFunesImg,
    name: 'Aldo Funes',
    position: 'Senior Software Engineer',
  },
  {
    image: raulMartinezImg,
    name: 'Raúl Martínez',
    position: 'Senior Software Engineer',
  },
  {
    image: edgardMoralesImg,
    name: 'Edgard Morales',
    position: 'Lead Front-end Engineer',
  },
  {
    image: albertoPickeringImg,
    name: 'Alberto Pickering',
    position: 'Software Engineer',
  },
  {
    image: kevinWooImg,
    name: 'Kyungtak Woo',
    position: 'Lead Security Engineer',
  },
  {
    image: rodrigoContrerasImg,
    name: 'Rodrigo Contreras',
    position: 'Senior Software Engineer',
  },
  {
    image: carlosFernandezImg,
    name: 'Carlos Fernández',
    position: 'Senior Software Engineer',
  },
  {
    image: jonathanBadilloImg,
    name: 'Jonathan Badillo',
    position: 'Software Engineer',
  },
  {
    image: jorgeDiazImg,
    name: 'Jorge Díaz',
    position: 'Software Engineer',
  },
  {
    image: sergioHernandezImg,
    name: 'Sergio Hernández',
    position: 'Software Engineer',
  },
];


const OurTeam = (props: Props) => (
  <div className="our-team container">
    <div className="row start-xs start-sm center-md">
      <div className="col-sm-12">
        <p className="our-team__heading">{props.heading}</p>
      </div>
    </div>

    <div className="row center-xs">
      {
        people.map(member => (
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" key={member.name}>
            <TeamMemberBadge
              image={member.image}
              name={member.name}
              position={member.position}
            />
          </div>
        ))
      }
    </div>
  </div>
);

export default OurTeam;
