// @flow
import * as React from 'react';
import classnames from 'classnames';
import { getSignUpUrl } from '../../utils/airtm/getAuthUrls';
import './Button.css';

type Props = {
  color: string,
  type: string,
  className?: string,
  children?: React.Node,
  link: string,
  isSignUpButton?: boolean,
};

const handleRedirect = (isSignUp: boolean, link: string) => {
  window.location.href = isSignUp === false ? link : getSignUpUrl();
};

const button = (props: Props) => {
  const classes = classnames([
    props.className,
    'button',
    'button--link',
    `button--color-${props.color}`,
  ], {
    'button--solid': props.type === 'solid',
  });

  return (
    <button className={classes} href="#" onClick={() => handleRedirect(props.isSignUpButton, props.link)}>
      {props.children}
    </button>
  );
};

button.defaultProps = {
  color: 'primary',
  type: 'outline',
  children: 'Button',
};

export default button;
