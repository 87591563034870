// @flow
import React from 'react';
import './MissionItem.css';

type Props = {
  image: string,
  text: string,
};

const MissionItem = (props: Props) => (
  <div className="mission-item">
    <div className="mission-item__img-container">
      <span className="mission-item__img" dangerouslySetInnerHTML={{ __html: props.image }} />
    </div>

    <p className="mission-item__text">{props.text}</p>
  </div>
);

export default MissionItem;
