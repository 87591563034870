// @flow
import React from 'react';
import './TeamMemberBadge.css';

type Props = {
  image: string,
  name: string,
  position: string,
};

const TeamMemberBadge = (props: Props) => (
  <div className="team-member">
    <img src={props.image} className="team-member__img" alt="" />
    <p className="team-member__name">{props.name}</p>
    <p className="team-member__position">{props.position}</p>
  </div>
);

export default TeamMemberBadge;
