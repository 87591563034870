import React from 'react';
import intl from 'react-intl-universal';
import CircleTile from '../../CircleTile/CircleTile';

import CloseIcon from '../../../assets/images/close.svg';
import './ModalContent.css';

type Props = {
  title: string,
  clicked: void => (void),
  countries?: Array,
  payments?: Array,
  mobile?: Array,
  cryptos?: Array,
  transfer?: Array,
  gift?: Array,
};

const ModalContent = (props: Props) => {
  const listCountries = props.countries.map((country, index) => (
    <div key={index} className="column__item">
      <CircleTile name={country.icon} size={32} />
      <span>{country.name}</span>
    </div>
  ));

  const listPayments = props.payments.map((payment, index) => (
    <div key={index} className="column__item">
      <CircleTile name={payment.icon} size={32} />
      <span>{payment.name}</span>
    </div>
  ));

  const listMobile = props.mobile.map((movil, index) => (
    <div key={index} className="column__item">
      <CircleTile name={movil.icon} size={32} />
      <span>{movil.name}</span>
    </div>
  ));

  const listTransfer = props.transfer.map((trans, index) => (
    <div key={index} className="column__item">
      <CircleTile name={trans.icon} size={32} />
      <span>{trans.name}</span>
    </div>
  ));

  const listGift = props.gift.map((g, index) => (
    <div key={index} className="column__item">
      <CircleTile name={g.icon} size={32} />
      <span>{g.name}</span>
    </div>
  ));

  return (
    <div className="modal__content">
      <div className="modal__content__header">
        <span className="modal__content__header-title">{props.title}</span>
        <div className="modal__content__header-icon" onClick={props.clicked} dangerouslySetInnerHTML={{ __html: CloseIcon }}></div>
      </div>
      <hr />
      <div className="modal__content-titles conteiner-fluid">
        <div className="modal__content-row">
          <div className="column">
            <span className="column__title">{intl.get('MODAL.GROUP-ONE')}</span>
            {listCountries}
          </div>
          <div className="column">
            <span className="column__title">{intl.get('MODAL.GROUP-TWO')}</span>
            {listPayments}
            <span className="column__title">{intl.get('MODAL.GROUP-THREE')}</span>
            {listMobile}
          </div>
          <div className="column">
            <span className="column__title">{intl.get('MODAL.GROUP-FIVE')}</span>
            {listTransfer}
          </div>
          <div className="column">
            <span className="column__title">{intl.get('MODAL.GROUP-SIX')}</span>
            {listGift}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalContent;
