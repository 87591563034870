// @flow
import React from 'react';
import ellipsis from '../../utils/string/ellipsis';
import './BlogPost.css';

type Props = {
  image?: string,
  category: string,
  categoryColor?: string,
  title: string,
  excerpt: string,
  link: string,
};

const blogPost = (props: Props) => (
  <div className="blog-post">
    <img src={props.image} className="blog-post__img" alt="" />
    <p
      className={`blog-post__category ${
        props.categoryColor === '' ? '' : ` blog-post__category--${props.categoryColor}`
      }`}
    >
      {ellipsis(props.category, 36)}
    </p>
    <div className="blog-post__title">
      <p>{ellipsis(props.title, 50)}</p>
    </div>
    <p className="blog-post__excerpt"
      dangerouslySetInnerHTML={{ __html: ellipsis(props.excerpt, 60) }}></p>
    <a className="blog-post__link" href={props.link} rel="noopener noreferrer" target="_blank">
      Leer más →
    </a>
  </div>
);

blogPost.defaultProps = {
  image: 'https://placekitten.com/396/248',
  categoryColor: '',
};

export default blogPost;
