import React from 'react';
import intl from 'react-intl-universal';
import ReactModal from 'react-modal';
import ModalContent from './ModalContent/ModalContent';

import './Modal.css';

type Props = {
  text: string,
};

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

ReactModal.setAppElement('#app');

class Modal extends React.Component<Props> {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState(
      {
        modalIsOpen: true,
      },
    );
  }

  closeModal() {
    this.setState(
      {
        modalIsOpen: false,
      },
    );
  }

  render() {
    const countries = [
      { icon: 'mexico', name: 'México' },
      { icon: 'panama', name: 'Panáma' },
      { icon: 'argentina', name: 'Argentina' },
      { icon: 'peru', name: 'Perú' },
      { icon: 'chile', name: 'Chile' },
      { icon: 'colombia', name: 'Colombia' },
      { icon: 'us', name: 'Estados Unidos' },
      { icon: 'venezuela', name: 'Venezuela' },
      { icon: 'world', name: 'World' },
    ];

    const payments = [
      { icon: 'money-gram', name: 'Money Gram' },
      { icon: 'western-union', name: 'Western Union' },
      { icon: 'rapipago', name: 'Rapipago' },
      { icon: 'pago-facil', name: 'Pago fácil' },
    ];

    const mobile = [
      { icon: 'orange', name: 'Orange' },
      { icon: 'mtn', name: 'MTN Mobile' },
      { icon: 'mpesa', name: 'Mpesa' },
      { icon: 'airtel', name: 'Airtel' },
      { icon: 'daviplata', name: 'DaviPlata' },
      { icon: 'nequi', name: 'Nequi' },
    ];

    const transfers = [
      { icon: 'uphold', name: 'Uphold' },
      { icon: 'perfect-money', name: 'Perfect Money' },
      { icon: 'payeer', name: 'Payeer' },
      { icon: 'mercadopago', name: 'Mercado Pago' },
      { icon: 'skrill', name: 'Skrill' },
      { icon: 'tenpay', name: 'Tenpay' },
      { icon: 'ali-pay', name: 'Ali Pay' },
      { icon: 'neteller', name: 'Neteller' },
      { icon: 'adv-cash', name: 'Advcash' },
    ];

    const gift = [
      { icon: 'airbnb', name: 'Airbnb' },
      { icon: 'google-play', name: 'Google Play' },
      { icon: 'itunes', name: 'iTunes' },
      { icon: 'netflix', name: 'Netflix' },
      { icon: 'playstation', name: 'Play Station' },
      { icon: 'spotify', name: 'Spotify' },
      { icon: 'starbucks', name: 'Starbucks' },
      { icon: 'steam', name: 'Steam' },
      { icon: 'xbox', name: 'Xbox' },
    ];
    return (
      <div className="modal">
        <span className="modal__text" onClick={this.openModal}>{this.props.text}</span>
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="example modal"
        >
          <ModalContent
            title={intl.get('MODAL.TITLE')}
            clicked={this.closeModal}
            countries={countries}
            payments={payments}
            mobile={mobile}
            transfer={transfers}
            gift={gift}
          />
        </ReactModal>
      </div>
    );
  }
}

export default Modal;
