// @flow
import React from 'react';
import intl from 'react-intl-universal';
import './CompanyHero.css';

const CompanyHero = () => (
  <header className="hero-company">
    <div className="hero-company__container container">
      <p className="hero-company__quote">{intl.getHTML('COMPANY.HEADER.TITLE')}</p>
      <p className="hero-company__person">- Ruben Galindo, CEO</p>
    </div>
  </header>
);

export default CompanyHero;
