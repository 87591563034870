// @flow
import React from 'react';
import classnames from 'classnames';
import './StartItem.css';

type Props = {
  icon: Object,
  number: string,
  description: string,
  line: string,
};

const startItem = (props: Props) => {
  const classes = classnames([
    'start__content__item-line',
    `${props.line}`,
  ]);

  return (
    <div className="start__content__item col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <div className="box">
        <div className="row">
          <div className="start__content__item-icon col-lg-12 col-md-12 col-sm-3 col-xs-3" dangerouslySetInnerHTML={{ __html: props.icon }}>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className={classes}></div>
            <span className="start__content__item-dot">{props.number}</span>
          </div>
          <div className="start__content__item-description col-lg-12 col-md-12 col-sm-9 col-xs-9">
            <span>
              {props.description}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

startItem.defaultProps = {
  line: '',
};

export default startItem;
