// @flow
import React from 'react';
import intl from 'react-intl-universal';
import CompanyHero from './CompanyHero/CompanyHero';
import Footer from '../../components/Footer/Footer.jsx';
import CompanyMission from './CompanyMission/CompanyMission';
import OurTeam from './OurTeam/OurTeam';
import OurInvestors from './OurInvestors/OurInvestors';
import CompanyMasonry from './CompanyMasonry/CompanyMasonry';
import './Company.css';

type Props = {};

type State = {};

class Company extends React.Component<Props, State> {
  render() {
    return (
      <div style={{ overflowX: 'hidden' }}>
        <div className="company">
          <CompanyHero />
          <div className="company-mission-container">
            <CompanyMission />
          </div>
          <div className="company-ourteam-container">
            <OurTeam heading={intl.get('COMPANY.AIRTM-ABOUT.TEAM')} />
          </div>
          <div className="company-investors-container">
            <OurInvestors heading={intl.get('COMPANY.AIRTM-ABOUT.INVERSORS')} />
          </div>
        </div>
        <CompanyMasonry />
        <Footer />
      </div>
    );
  }
}

export default Company;
