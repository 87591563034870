// @flow
import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import {
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import cookie from 'react-cookie';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import uuidv4 from 'uuid/v4';
import Navbar from 'Components/Navbar/Navbar';
import CookieBar from 'Components/CookieBar/CookieBar';
import WelcomeBar from 'Components/WelcomeBar/WelcomeBar';
import constants from './constants';
import AuthStateContext from './context/AuthState';
import Home from './views/Home/Home';
import Company from './views/Company/Company';
import Free from './views/Free/Free';
import Save from './views/Save/Save';
import Send from './views/Send/Send';
import Legal from './views/Legal/Legal';
import { mapDispatchToProps } from './services/airtm/connect';
import esMx from './locales/es-MX.json';
import enUS from './locales/en-US.json';

const { LOCAL_STORAGE } = constants;

type Props = {
  getExchangeRates: Function,
  getBlogPosts: Function,
};

const locales = {
  es: esMx,
  en: enUS,
};

class App extends Component<Props> {
  constructor() {
    super();
    this.state = {
      initDone: false,
      authState: uuidv4(),
    };
    this.loadLocales = this.loadLocales.bind(this);
    this.getDomainUgly = this.getDomainUgly.bind(this);

    if (window.localStorage.getItem(LOCAL_STORAGE.LANGUAGE) == null) {
      const currentLocale = navigator.language.includes('es')
        ? 'es'
        : 'en';
      window.localStorage.setItem(LOCAL_STORAGE.LANGUAGE, currentLocale);
    }
  }

  componentDidMount() {
    this.props.getExchangeRates();
    this.props.getBlogPosts();
    this.loadLocales();
    this.checkLogin();
    cookie.save('Cloud-ATM-OAuth-State', this.state.authState, { path: '/' });
  }

  loadLocales() {
    const currentLocale = window.localStorage.getItem(LOCAL_STORAGE.LANGUAGE);

    intl.init({
      currentLocale,
      locales,
    }).then(() => {
      this.setState({ initDone: true });
    });
  }

  getDomainUgly(location, subdmn) {
    const subdomain = subdmn || false;
    let url = location.replace(/(https?:\/\/)?(www.)?/i, '');

    if (!subdomain) {
      url = url.split('.');
      url = url.slice(url.length - 2).join('.');
    }

    if (url.indexOf('/') !== -1) {
      return url.split('/')[0];
    }

    return `.${url}`;
  }

  checkLogin() {
    // const hostname = window.location.hostname.replace('www.', '');
    // window.location = `${window.location.protocol}//app.${hostname}`;
  }

  setReferralCookie(props) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 4);
    cookie.save('Cloud-ATM-Referral-Invite-Code', props.match.params.code, {
      path: '/',
      expires: expirationDate,
    });
    return (
      <Home {...props}/>
    );
  }

  render() {
    return (
      <div style={{ overflowX: 'hidden' }}>
        <AuthStateContext.Provider value={this.state.authState}>
          <WelcomeBar
            isHidden={true}
            text={intl.get('BANNERS.WELCOME.TEXT')}
            buttonText={intl.getHTML('BANNERS.WELCOME.BUTTON')}
          />
          <Navbar />
          <CookieBar
            isHidden={true}
            text={intl.get('BANNERS.COOKIE.TEXT')}
            buttonText={intl.get('BANNERS.COOKIE.BUTTON')}
          />
          <main className="app">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/invite/:code" render={props => this.setReferralCookie(props)}/>
              <Route path="/about-us" component={Company} />
              <Route path="/free" component={Free} />
              <Route path="/save" component={Save} />
              <Route path="/send" component={Send} />
              <Route path="/legal" component={Legal} />
            </Switch>
          </main>
        </AuthStateContext.Provider>
      </div>
    );
  }
}

const connectedApp = withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(App),
);

export default hot(module)(connectedApp);
